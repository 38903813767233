.hotel-missed-saving-details {
	padding: 0.725rem;
	border: 10px solid $brand-color-3;
	margin-bottom: 0.725rem;
	font-family: $font-family-number;
	
	&__heading-container {
		@include clearfix;
		display: inline-table;
    	width: 100%;
    	margin-bottom: 0.5rem;
		border-bottom: 1px solid $brand-color-3;
		
		&_heading, &_close-button {
			display:inline-block;
	   		font-size: 1.25rem;
		    padding-bottom: 0.5rem;
		}
		
		&_heading {
		    width: 90%;
		    float:left;
		    color: $brand-color-2;
		    text-align: center;
		}
		
		&_close-button {
			float:right;
			text-align: right;
			cursor: pointer;
		}
	}
	
	&__header,&__value {
		@include clearfix;
		width:100%;
		min-height: 1.6rem;
    	padding: 0.25rem;
	
		&_llf-no,&_amount,&_missed-amount,&_hotel-details{
			display:inline-block;
			text-align: left;
			}
			
		&_llf-no,&_amount,&_missed-amount {
			width:15%;
		}
		
		&_hotel-details{
			width:50%;
		}	
		
		}
		
	&__header{
		background-color:$brand-color-3;
	}	
		
	&__row{
		&:not(:first-child) {
    		border-top: 1px solid $brand-color-3;
		}
	}
}
