.transfer-information {
	padding: 0.725rem;
	border: 0.625rem solid $brand-color-3;
	margin-bottom: 0.725rem;
	font-family: $font-family-number;
	
	&__heading-container {
		@include clearfix;
		
		&_heading, &_close-button {
			float: left;
	   		font-size: 1.25rem;
		    border-bottom: 0.0625rem solid $brand-color-3;
		    padding-bottom: 0.5rem;
		    margin-bottom: 0.5rem;
		}
		
		&_heading {
		    width: 90%;
		    color: $brand-color-2;
		    text-align: center;
		}
		
		&_close-button {
			width: 10%;
			text-align: right;
			cursor: pointer;
		}
	}
	
	&__details {
		&_info-container {
			@include clearfix;
			padding: 0.125rem;
			
			.transferInfo {
				@include clearfix;
				margin-bottom: 0.25rem;
				&__label{
					float: left;
					color: $brand-color-2;
				}
				&__value,&__separator{
					color: $brand-color-5;
				}
				&__separator{
					margin-right: 0.25rem;
				}
			}
		}
		&_loader{
			text-align:center;
		}
	}
}