.transfer-total-price-details {
	&__total-price-overview {
		@include clearfix;
    	.total-price-container {
    		&__total-price {
				float: left;
				min-width: 25%;
				font-size: 1.25rem;
    		}
    	}
		&_total {
			@include clearfix;
			float: right;
			width: 25%;
			
			.total-price-container {
				float: right;
				
				&__price {
					@include clearfix;
					float: left;
    				
					&_currency {
					    margin-right: 5px;
					}
					&_amount {
					    font-size: 1.25rem;
					    color: $brand-color-1;
					    font-weight: 200;
					}
				}
				
				&__expand-collapse-icon {
					float: left;
					cursor: pointer;
					font-size: 1.5rem;
					margin-top: -3px;
					margin-left: 0.5rem;
					min-width: 25px;
	    		}
	    		&__expand-collapse-icon-expanded {
	    			color: $gray;
	    		} 
			}
			
		}
	}
	
	
	&__details-container {
		border: 2px solid $brand-color-3;
		
		&_header-row, &_value-row {
			@include clearfix;
			
			.header-row {
				&__label {
					float: left;
					width: 40%;
					text-transform: uppercase;
    				color: $brand-color-2;
    				padding: 0.25rem;
    				text-align: center;
				}
			}
			
			.value-row {
				&__label {
					float: left;
					width: 40%;
					text-transform: uppercase;
    				padding: 0.25rem;
    				text-align: center;
    				&_expander {
    					cursor: pointer;
    				}
    				&_expanded {
   						color: $gray;
    				}
				}
			}
		}
	}
	
}