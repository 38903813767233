.hotel {
	&__room {
		&_price-breakup {
			@include clearfix;
    		background-color: $light-gray;
    		border: 2px solid $brand-color-3;
    		font-size: .875rem;
    		padding: .5rem;
			.price-breakup{
				&__details, &__extra-details {
					@include clearfix;
					width: 65%;
					float: right;
					&_header{
						@include clearfix;
						border-bottom: 1px solid $separator-color;
						padding: .5rem;
						.header{
							&__check-in,
							&__per-night-price {
								color: $brand-color-2;
								float: left;
								width: 50%;
							}
							&__per-night-price {
								text-align: right;
								&_currency {
									font-size: .875rem;
								}
							}
						}
					}
					
					&_content-container{
						@include clearfix;
    					border-bottom: 1px solid $separator-color;
   					    padding: .5rem 0;
						.content {
							padding: .5rem;
							width: 100%;
							&__duration, &__extraTitle {
								float: left;
							    padding: 0 .5rem;
								width: 60%;
								&_check-in,
								&_check-out, &_label {
									color:$gray;
									float: left;
									font-family: $font-family-number;
								}
								&_check-in:after {
									content : '-';
									margin : .5rem;
								}
							}
							&__per-night-price, &__extraAmount {
								color:$gray;
								float: right;
							    padding-right: .5rem;
								text-align: right;
								width: 40%;
								&_amount {
									font-family: $font-family-number;
									font-size: .875rem;
								}
							}
						}
					}	
					&_content-container:last-child {
						border-bottom: none;
					}
				}
				&__extra-details{
					border-top: 1px solid $separator-color;
				}
			}
		}
	}
}