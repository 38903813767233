.common-total-earning-details, .extras {
	padding: 0.5rem 1rem;
    border: 2px solid $gray-lighter;
    font-size: $font-size-h4;
    
    &__price-row {
    	@include clearfix;
    	
    	&_label, &_price-container {
    		float: left;
    		width: 50%;
    	}
		
		&_label {
			font-size: 1.25rem;
		}
		
		&_label-expanded {
			color: $gray;
		}
		
		&_price-container {
    		float: left;
    		width: 50%;
    		
	    	.price-container {
		    	@include clearfix;
	   			float: right;
	   			
	   			&__currency, &__amount, &__expander {
	   				float: left;
	   			}
	   			
	   			&__currency {
	   				margin-right: 0.313rem;
	   				margin-top: 0.375rem;
					font-size: .8725rem;
	   			}
	   			
	   			&__amount {
	   				font-size: 1.25rem;
	   				color: $brand-color-1;
	   				font-weight: 500;
	   				font-family: $font-family-number;
	   			}
	   			
	   			&__expander {
   					font-size: $font-size-h2;
					margin-top: -3px;
					cursor: pointer;
					margin-left: 0.5rem;
					min-width: 25px;
	   			}
	   			
	   			&__expanded {
   					color: $gray;
	   			}
	   		}
    	}
    	    	
    }
    
    &__extra-details {
    	&_container{
	    	border: 2px solid $brand-color-3;
			background-color: $brand-color-4;
			padding: 0 2% 0 60%;
			font-size: $font-size-h4;
			
    		.extra-details{
				@include create-fare-details-extra-content(60%, 2%);
				&__msg{
					color: red;
					font-size: 0.8rem;
				}
    		}
    	}
    }
    &__title {
			font-size: 1.15rem;
			color: $brand-color-2;
		}
}
.pax-details{
	display:table;
    width:100%;
    content:"";

    &__pax{
	    display:table;
		float:left;
        width:33%;
        margin-top:1.25rem;
        border-left: 0.063rem solid  $brand-color-3;

		&_pax-info{
			padding-left: 0.313rem;
			.pax-info{
				&_label{
					color: $brand-color-2;
				}
			}
		}
   }
}

.pax-count{
	margin-top: 1rem;
	&__title{
		color: $brand-color-2;
	}
}

.pax-details__pax:nth-child(3n + 1) {
    border-left:unset;
}
