.dubz-details{
	
	border: 2px solid $brand-color-3;
    padding: 1rem;
	@include clearfix;
	width: 100%;
	
	&__contain,&__address{
		font-size: .875rem;
		font-weight: 400;
		float: left;
		width: 50%;
		&_title{
			color: $brand-color-2;
		}
		&_value{
			margin-top: 0.313rem;
			margin-bottom: 1rem;
		}
	}
	&__address{
		width: 100%;
	}
}

.dubz-details-header{
	background-color: $brand-color-3;
	color: $brand-color-2;
	font-size: 1.25rem;
	font-weight: 500;
	padding-left: .5rem;
	width: 100%;
}