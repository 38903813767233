%border {
    color: $gray;
    cursor: pointer;
    border: 1px solid $gray-lighting;
    border-radius: 4px;
    float: left;
    margin-right: .5rem;
    padding: 0.25rem;
}

%highlight {
    background-color: $brand-color-2;
    color: $light;
}

.document-download-link-container {
    &__label-container {
        @extend %border;

        text-transform: capitalize;

        &:hover {
            @extend %highlight;
        }
    }
    
    &__multiple-document-container {
        @include clearfix;
        @extend %border;
        
        margin-bottom: 0.5rem;
        width: 30%;

        &_label {
            .multiple-document-container-label__toggle-indicator {
                float: right;
            }
        }

        &_label-open {
            margin-bottom: 0.5rem;
        }

        &_document-listing {
            @extend %border;
            
            float: none;
            margin-bottom: 0.5rem;
            margin-right: 0;
            
            .document-listing {
                &__invoice-download-link {
                    font-family:  $font-family-number;
                }
            }

            &:hover {
                @extend %highlight;

            }
        }
        &_document-listing:last-child {
            margin-bottom: 0;
        }
    }
}