.hotel-details__container {
	@include clearfix;
	
	&_hotel-address {
		font-size: 1rem;
		min-height: 1px;
	}
	&_hotel-contact-no {
		font-size: 1rem;
		min-height: 1px;
		&-label{
			color: $brand-color-2;
		}
	}
	
	&_hotel-basic-info {
		.hotel-basic-info {
			&__hotel-name {
				font-size: 2rem;
				color: $brand-color-2;	
			}
			&__hotel-star-rating {
				color: $star-rating;
			    font-size: 1rem;
			    vertical-align: middle;
			}
		}
	}
	&_meal-plan{
		font-size: 1rem;
		.meal-plan{
			&__title{
				color: $brand-color-2;	
			}
		}
	}
}
.hotel {
	&__booking-room-info {
		margin-top: 1rem;
		&_header {
			color: $brand-color-2;	
			font-size: 1.25rem;
		}
		&_details{
			@include clearfix;
		}
		&_extra-details{
			@include clearfix;
			border: 2px solid $gray-lighter;
    		font-size: 0.875rem;
    		padding: 0.5rem 1rem;
    		.extra-details{
				&__label{
					color: $brand-color-2;
					float: left;
					width: 50%;
				}
				&__detail-container{
					@include clearfix;
					width: 70%;
					&_content{
						width: 50%;
						float:left;
					}
				}
			}
		}
	}
}