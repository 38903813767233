.reinitiate-ticketing {
	&__transition-form {
		label {
			color: $brand-color-2;
			font-weight: 700;
		}
		font-weight: 400;
		font-size: 14px;
		font-family: Helvetica Neue,Helvetica,Arial,sans-serif;
	}
}