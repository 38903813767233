$desktop-sm: "(min-width: #{$sm-screen-size})";
$desktop-md: "(min-width: #{$md-screen-size})";
$desktop-lg: "(min-width: #{$lg-screen-size})";
@mixin desktop-med {
  @media #{$desktop-md} {
    @content;
  }
}
@mixin desktop-large {
  @media #{$desktop-lg} {
    @content;
  }
}
@mixin make-responsive-width_private($property-name, $sm, $md:null, $lg:null) {
  #{$property-name}: $sm;
  
  
  @if $md != null {
   
    @include desktop-med {
      #{$property-name}: $md;
    }
  }
  @if $lg != null {
    @include desktop-large {
      #{$property-name}: $lg;
    }
  }
  @content;
}

@mixin  make-responsive-width($property-name, $sm, $md:null, $lg:null) { 
	@include make-responsive-width_private($property-name, $sm, $md, $lg); 
}