.svc-product {
    &__title{
   		background-color: $brand-color-3;
   		color: $brand-color-2;
    	font-size: 1.25rem;
    	padding-left: .5rem;
    	width: 100%;
    	font-weight: 500;
    }
	&__details {
		width: 100%;
		display: table;
		content:'';
	    border: 2px solid $brand-color-3;
        padding: 0.7rem;
        &_air-details{
        	font-size: .875rem;
    	    width: 100%;
    	    .air-details{
    	    	&__block {
					font-size: 0.875rem;
					@include clearfix;
					width: 100%;
		
					&_title {
						color: $brand-color-2;
					}
					&_separator {
						color: $brand-color-2;
						margin-left: .25rem;
						margin-right: .25rem;
					}
        	    }
        	}	
        }
		&_content {	
			box-sizing: inherit;
    		padding-top: 1.3125rem;
    		padding-bottom: .3125rem;
    		display: flex;
    		.content{
    			&__pax-details{
					&_pax-basic-details {
						float: left;
						width: 50%;
						.pax-basic-details {
				
							&__pax-name {
								font-size: 0.9375rem;
								color: $brand-color-2;
							}
				
							&__ticket-number, &__pnr-number {
								font-size: 0.75rem;
							}
				
							&__pax-details-container {
								float: left;
								width: 50%;
					
								&_details {
									@include clearfix;
									font-size: 0.875rem;
						
									.pax-details {
										&__title, &__value, &__separator {
										float: left;
										}
										&__title {
											color: $brand-color-2;
										}
										&__separator {
											margin-left: 0.25rem;
											margin-right: 0.25rem;
										}
										&__value{
											font-family: $font-family-number;
										}
										&__nationality-code-block {
								 		   display: inline-table;
										}
									}
								}
							}
						}
					}
					&_pax-ticket-details {
						float: right;
						width: 50%;
			
						.pax-ticket-details {
							&__sector {
								@include clearfix;
								font-size: 0.875rem;
								margin-top: 0.25rem;
					
							&_info, &_ticket-number-container, &_ticket-status {
								float: left;
								word-wrap: break-word;
								font-size: $font-size-h5;
							}
							&_ticket-number-container {
								width: 50%;
								.ticket-number-container {
								
									&__ticket-number {
										font-family: $font-family-number;
										text-decoration: underline;
										padding-left: 0.5rem;
									} 
								}
							}
					
							&_info {
								width: 50%;
								@include clearfix;
							
								.sector-info {
									&__from-iata, &__to-iata {
										float: left;
										width: 35%;
									}
								
									&__separator {
										float: left;
										width: 30%;
									}
								}
							}
							&_ticket-number {
									width: 60%;
							}
						}
				
						&__sector-headings {
							@include clearfix;
							font-size: 0.875rem;
							color: $brand-color-2;
							width: 100%;
    				
							&_sector-name, &_ticket-number, &_ticket-status {
								float: left;
							}
							&_sector-name, &_ticket-number {
								width: 50%;
							}
						}
					}
				}
			}
		}
	}}
}