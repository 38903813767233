.sightseeing-transaction-details {
	
	&__supplier-container, &__ground-handler-container {
		@include clearfix;
		font-size: 1rem;
		
		&_title, &_value, &_separator {
			float: left;
		}
		
		&_title {
			color: $brand-color-2;
		}
		
		&_separator {
			margin-left: 0.5rem;
			margin-right: 0.5rem;
		}
	}
		
}