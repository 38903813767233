.air-pax-details {
	@include clearfix;
	
	&__title {
		font-size: 1.15rem;
    	color: $brand-color-2;	
	}
	
	&__ignore-api-block {
		&_remark-title {
			font-size: 0.9375rem;
			color: $brand-color-2;
		}
		&_separator {
			margin-left: 0.25rem;
			margin-right: 0.25rem;
		}
		&_remark-content {
			font-size: .875rem;
		}
	}
	
	&__content {
		@include clearfix;
		padding-top: 0.3125rem;
		padding-bottom: 0.3125rem;
		margin: 0.5rem;
		
		&_pax-basic-details {
			float: left;
			width: 40%;
			
			.pax-basic-details {
				
				&__pax-name {
					font-size: 0.9375rem;
					color: $brand-color-2;
				}
				
				&__ticket-number, &__pnr-number {
					font-size: 0.75rem;
				}
				
				&__pax-details-container {
					float: left;
					width: 50%;
					
					&_details {
						@include clearfix;
						font-size: 0.875rem;
						
						.pax-details {
							&__title, &__value, &__separator {
								float: left;
							}
							&__title {
								color: $brand-color-2;
							}
							&__separator {
								margin-left: 0.25rem;
								margin-right: 0.25rem;
							}
							&__value{
								font-family: $font-family-number;
							}
							&__nationality-code-block {
							    display: inline-table;
							}
						}
					}
				}
			}
		}
		
		&_pax-ticket-details {
			float: left;
			width: 60%;
			
			.pax-ticket-details {
				&__sector {
					@include clearfix;
					font-size: 0.875rem;
					margin-top: 0.25rem;
					
					&_info, &_ticket-number-container, &_ticket-status {
						float: left;
						word-wrap: break-word;
						font-size: $font-size-h5;
					}
					&_ticket-status {
						width: 35%;
					}
					&_ticket-number-container {
						width: 35%;
						.ticket-number-container {
							cursor: pointer;
							
							&__ticket-number {
								font-family: $font-family-number;
								text-decoration: underline;
							} 
							&__download-icon {
								color: $green;
							}
							&__cash-earned {
						       	width: 8%;
    							margin-bottom: .2rem;
    							margin-left: 0.2rem;
							}
						}
					}
					
					&_info {
						width: 30%;
						@include clearfix;
						
						.sector-info {
							&__from-iata, &__to-iata {
								float: left;
								width: 40%;
							}
							
							&__separator {
								float: left;
								width: 20%;
							}
						}
					}
					&_ticket-number {
						width: 60%;
					}
				}
				
				&__sector-headings {
					@include clearfix;
					font-size: 0.875rem;
					color: $brand-color-2;
					width: 100%;
    				
					&_sector-name, &_ticket-number, &_ticket-status {
						float: left;
					}
					&_sector-name {
						width: 30%;
					}
					&_ticket-number {
						width: 35%;
					}
					&_ticket-status {
						width: 35%;
					}
				}
			}
		}
		.air-services-action-controls{
			@include clearfix;
			float:right;
			&__air-services-action-controls {
				float:left;
			    padding-right: 0;
				&_action {
					@include make-button($light, $brand-color-2, $light, darken($brand-color-2,20));
					padding: 0.525rem;
		    		margin-right: 0.125rem;
				}
			}
		}
				
		&_pax-extra-details {
			@include clearfix;
			font-size: 0.875rem;
			margin-top: 0.5rem;
			float: left;
			width: 100%;
			
			.pax-extra-details {
				&__service-details-container, &__pax-details-container {
					float: left;
					width: 50%;
					
					&_details {
						@include clearfix;
						
						.pax-details {
							&__title, &__value, &__separator {
								float: left;
							}
							&__title {
								color: $brand-color-2;
							}
							&__separator {
								margin-left: 0.25rem;
								margin-right: 0.25rem;
							}
							&__ff-vendor-block {
							    display: inline-table;
							}
							&__value{
								&_invoice-number{
									font-family: $font-family-number;
									text-decoration: underline;
									cursor: pointer;
								}	
								&_download-icon{
									color : $green;
									cursor: pointer;					
								}						
							}
						}
					}
					
				}
			}
		}
	}
}