.download-document-container{
	width: 100%;
	&__doc-detail{
		cursor: pointer;
		&_content .content{
			&__document-number {
				font-family: $font-family-number;
				text-decoration: underline;
			}
			&__document-name{
				color: $brand-color-1;
				cursor: pointer;
				&:hover {
					text-decoration: underline;
				}
			} 
			&__download-icon{
				color: $brand-color-35;
				cursor: pointer;
			}
			&__dropdown-menu{
				color: $brand-color-32;
				min-width: -webkit-fill-available;
    			text-align: center;
				.dropdown-option{
					border-bottom: 2px solid $brand-color-22;
					&:last-child {
	          			border-bottom: unset;
	        		}
				}
			}
		}	
	}
}
