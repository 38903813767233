.new-company-detail-form-container {
    padding: 0.725rem;
    border: 0.7rem solid $brand-color-3;
    margin-bottom: 0.725rem;

    &__alert-message{
           @include clearfix;
           width: 100%;
    }
    &__heading {
            color: $brand-color-2;
            font-size: 1.25rem;
            text-align: center;
            border-bottom: 0.2rem solid $brand-color-3;
            padding-bottom: 0.5rem;
            margin-bottom: 0.5rem;
        
    }
    &__form {
        padding: 0.725rem;
        &_field {
            padding: 0.5rem;
             width : 100%;
            .field {
                &__field-label {
                    width: 5%;
                }
                
                &__field-value{
                    width: 50%;
                    background: none;
                    font-size: 0.875rem;
                    vertical-align: middle;
                    line-height: normal;
                    background-color: $light;
                    border: 0.2rem solid;
                    padding: 0.80rem;
                    border-color: $gray-lighter;
                }
            
                &__field-validation-error {
                    color : $brand-color-1;
                    padding-left: 4.5rem;
                }
            
                &__control-buttons {
                    @include clearfix;
                    width: 100%;
                    margin: 0 auto;
                    &_send-data{
                        @include make-button($light, $brand-color-2, $light, darken($brand-color-2,20));
                        padding : 0.3125rem;
                        font-weight: 700;
                        width: 15%;
                        float: right;
                        margin-right: 0.3125rem;
                    }
                }
            }
        }
        .cash-diners-details{
            padding-right: unset;
            padding-left: 18%;
            .cash-diners-fieldset{
                @include clearfix;
                width: 32%;
                display: grid;
                &__values{
                    line-height: 2rem;
                    input[type="text"] {
                        padding: 0.5rem 0rem;
                    }
                }
            }
        }
    }
}
