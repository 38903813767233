.transfer-price-details {
	@include clearfix;
	width: 100%;
	font-size: 0.875rem;
	font-family: $font-family-number;
	
	&__title {
		font-size: 1.15rem;
    	color: $brand-color-2;	
	}
	
	&__content {
		@include clearfix;
		padding: 0.5rem 1rem;
		border: 2px solid $gray-lighter;
		&_price-details {
			margin-top: 0.5rem;
			&:first-child {
				margin-top: 0;
			}
		}
	}
	
	&__total-price-details {
    	margin-top: 0.3125rem;
	}
}