.sightseeing-pax-type-price-details {
	&__pax-detail-overview {
		@include clearfix;
		
		&_pax-type-container {
			@include clearfix;
			float: left;
			width: 30%;
			font-size: 1.25rem;
	    	
	    	.pax-type-container {
	    		&__pax-type {
					float: left;
					min-width: 20%;
					margin-right: 0.3125rem;
	    		}
	    	}
		}
		
		&_total-fare {
			float: left;
			width: 25%;
			text-align: center;
			font-size: .875rem;
			
			.total-fare {
				&__amount {
				    color: $brand-color-1;
				    font-weight: 200;
				    font-size: $font-size-number;
					font-family: $font-family-number;
				}
			}
		}
		&_multiply {
			float: left;
			width: 5%;
		    font-size: 1.25rem;
			text-align: center;
		}
		&_no-of-pax {
			float: left;
			width: 15%;
		    font-size: 1.25rem;
			text-align: center;
		}
		
		&_pax-type-expanded {
			color: $gray;
		}
		
		&_total {
			@include clearfix;
			float: left;
			width: 25%;
    		padding-right: 1.875rem;
			
			.total-price-container {
				float: right;
				
				&__price {
					@include clearfix;
					float: left;
    				
					&_currency {
					    margin-right: 5px;
					    font-size: .875rem;
					}
					&_amount {
					    font-size: $font-size-number;
					    color: $brand-color-1;
					    font-weight: 200;
					    font-family: $font-family-number;
					}
				}
			}
		}
	}
}