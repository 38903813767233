.packages-details__container {
	@include clearfix;
	
	&_packages-address {
		font-size: 1rem;
		min-height: 1px;
	}
	
	&_packages-basic-info {
		.packages-basic-info {
			&__packages-name {
				font-size: 2rem;
				color: $brand-color-2;	
			}

		}
	}

}
