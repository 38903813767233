@mixin hide-number-spinner_private{
	input[type="number"]::-webkit-inner-spin-button, 
	     input[type="number"]::-webkit-outer-spin-button { 
	         -webkit-appearance: none; 
	 }
	input[type="number"] {-moz-appearance: textfield;}	
}

@mixin  hide-number-spinner{ 
	@include hide-number-spinner_private; 
}