.visa-transaction-details {
	
	&__content {
		font-size: 1.15rem;
		&_title {
			color: $brand-color-2;
		}
	}
	
	&__form-content{
			padding-top: 1rem;
			font-size: 1rem;
	}
	.visa-basic-info {
		float: left;
		display: block;
    	width: 100%;
    	padding-bottom: 0.9rem;
		&__sightseeing-name {
			font-size: 2rem;
			color: $brand-color-2;
		}
			
		&__location {
			@include clearfix;
			font-size: 1.15rem;
			
			&_seperator {
				float: left;
			}
			&_country {
				float: left;
			}
		}
	}
}