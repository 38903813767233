.visa-pax-info {
	padding-top: 0.9rem;
	padding-bottom: 0.9rem;
	
	&__header {
  		width: 100%;
		background: $brand-color-3;
		&_title {
			color: $brand-color-2;
			font-size: 1.25rem;
   	 		padding-left: 0.5rem;
    		font-weight: 500;
		}
	}
	
	&__content {
    	@include clearfix;
		width: 100%;
		font-size: 0.875rem;
	    display: table;
    	border: 2px solid $brand-color-3;
   		padding: 0.5rem;
		&_field {
			width: 33%;
			float: left;
			min-height: 3.5rem;
			.field-label  {
				margin-bottom: 0;
    			color: $brand-color-2;
    			font-size: .875rem;
			}
			.field-value {
				display: block;
    			width: 100%;
			}
		}
	}
}