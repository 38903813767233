@mixin create-pricing-content_private {
	&__header {
		@include clearfix;
		&_clickable {
			cursor: pointer;
		}
	    border: 2px solid $gray-lighter;
		font-size: 1.25rem;
		padding: .5rem 1rem;
		
		&_total-amount-label, &_price-container {
			float: left;
			width: 50%;
		}
		
		&_price-container {
			@include clearfix;
			
			.price-container {
				float: right;
			
				&__amount, &__currency, &__expand-button {
					float: left;
				}
				
				&__currency {
					font-size: .8725rem;
	   				margin-right: .3125rem;
	   				margin-top: .375rem;
	   			}
	   			
				&__amount {
				    color: $brand-color-1;
				    font-family: $font-family-number;
					font-size: 1.25rem;
				    font-weight: 500;
				}
				
				&__expand-button {
					font-size: $font-size-h2;
					margin-top: -.1875rem;
					margin-left: 0.5rem;
					min-width: 1.5625rem;
				}
			}
		}
	}
	
	&__header-dark {
	    color: $gray;
	}
	
	&-content {
		@include create-fare-details-extra-content(60%, 2%);
	}
}

@mixin  create-pricing-content { 
	@include create-pricing-content_private; 
}