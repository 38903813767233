.transaction-lock-summary {
  border-bottom: 1px solid $brand-color-2;
  padding: 5px 10px;

  &__lock-icon {
    display: inline-block;
    color: $brand-color-2;
  }

  &__label {
    display: inline-block;
    color: $brand-color-9;
    margin: 0;
  }

  &__unlock-button {
    display: inline-block;
    background-color: $brand-color-9;
    color: $brand-color-23;
    border-radius: 0.25rem;
    border-width: 0;

    &:hover {
      background-color: $brand-color-13;
    }
  }
}