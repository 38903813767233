.reissue-request{
	&__eligibility-details {
		&_header {
			@include clearfix;
			background-color: $brand-color-3; 
		}
		&_header > div {
			color: $brand-color-2;
			height: 4rem;
			text-align: center;
			vertical-align: middle;
		}
		&_content {
			border-bottom: 1px solid $brand-color-3;
			display: table;
			width: 100%;
		}
		&_header > div,
		&_content > div {
			@include clearfix;
			float: left;
			font-size: 1rem;
			padding: 0.5rem;
			width: 20%;
			word-break: break-word;
			text-align: center;
		}
		.content {
			&__ticket-number {
				font-family: $font-family-number;
			}
			&__is-eligible,
			&__is-geography-change-allowed,
			&__is-penalty-applicable {
				.fa-check {
					color: $green;
				}
				.fa-times {
					color: $brand-color-1;
				}
			}
		}
	}
	&__supported-airlines {
		@include clearfix;
		margin-top: 1rem;
		font-size: 0.875rem;
		&_label {
			color: $brand-color-2;
		}
		&_values {
			@include make-one-third();
    		float: left;
    		padding-bottom: 0.25rem;
    		padding-right: 0.25rem;
    		padding-left: 0;
		}
	}
	&__control-buttons {
		text-align: center;
	}
}
.reissue-buttons {
	&__confirm-cancel, &__close {
		@include make-button($light, $row-book-button-bgcolor, $light, darken($row-book-button-state,20));
		float : none;
		margin-left: 0.5rem;
		margin-right: 0.125rem;
		margin-top: 0.525rem;
		padding: 0.525rem;
		width: 30%;
	}
}