.air-ssr-service {
    &__transition-form {
        &_form {
            .transition-form {
                &__close-button {
                    float: right;
                    cursor: pointer;
                }
                &__heading {
                    color: $brand-color-2;
                    font-size: 1.25rem;
                    text-align: center;
                    border-bottom: 1px solid $brand-color-3;
                    padding-bottom: 0.5rem;
                    margin-bottom: 0.5rem;
                }
            }
        }
        .air-ssr-details {
            @include clearfix;
            &__header-container {
                @include clearfix;
                width: 100%;
                &_title {
                    background-color: $brand-color-3;
                    width: 25%;
                    padding: 0.5rem;
                    float: left;
                }
            }
            &__header-osi {
                @include clearfix;
                width: 100%;
                &_title-osi {
                    background-color: $brand-color-3;
                    width: 50%;
                    padding: .5rem;
                    float: left;
                }
            }
            &__value-container {
                @include clearfix;
                width: 100%;
                &_value, &_value-osi {
                    background-color: $light;
                    width: 25%;
                    padding: 0.5rem;
                    float: left;
                }
                &_value-osi {
                    width: 50%;
                }
            }
	        &__baggage-info-icon {
	    		font-size: 1rem;
	    		color: $brand-color-2;
			}
        }
        .air-endorsement-information {
            @include clearfix;
            &__header {
                @include clearfix;
                width: 100%;
                &_title {
                    background-color: $brand-color-3;
                    width: 100%;
                    padding: .5rem;
                    float: left;
                }
                
            }
            &__value-container {
                @include clearfix;
                width: 100%;
                &_value{
                    background-color: $light;
                    width: 100%;
                    padding: 0.5rem;
                    float: left;
                }
                
                
            }
        }
    }
}