$color-black: #191919;
$color-blue-btn: #127dce;
$color-blue-highlight-btn: #116aae;
$color-background-grey: #fff;
$color-highlight-grey: #d8d8d8;
$color-label-grey: #808080;
$color-highlight-blue: #cde8fd;
$color-white-text: #fff;
$color-black-text: #262626;
$pixel-unit-o: 0px;
$pixel-unit-xs: 1px; 
$pixel-unit-s: 2px; 
$pixel-unit-m: 5px;
$pixel-unit-l: 10px;
$pixel-unit-xl: 15px; 