@use "sass:math";

.user-feedback-form {

	@include create-main-wrapper;
    position: relative;
	
	&_wrapper {
		@include clearfix;
		margin-top: 1rem;
		margin-bottom: 1rem;
		width: 850px;
        background-color: $advertise-bgcolor;
    }
    
    @include create-row-card;	
	
	&__card{
		margin: 1.5rem;
	    padding: 0.5rem;
        background-color: $advertise-bgcolor;
        border: 0.5rem solid;
    	border-color: $brand-color-3;
	};
    
    &__header{
    	text-transform: none;
   		 color: $brand-color-2;
   		 margin-bottom: $base-padding*0.5;
   		 padding: math.div($base-padding, 6) $base-padding*0.5;
   		 padding-left: 0px;
	     text-align: center;
   		 
   		 &_text{
		    font-size: 2rem;
	     	font-weight: 900;
   		 }
	}
	
	&__error {
		 color: $brand-color-1;
		 font-size: 1.5rem;
    	 margin: 2rem;
	}
	&__button-container {
		width : 50%;
		margin : 0 auto;
		&_button {
		    padding-top: 0.3125rem;
		    padding-bottom: 0.3125rem;
		    background-color: $brand-color-1;
		    width: 100%;
		    font-size: 1rem;
		    color: $light;
		    font-weight: 700;
		    &:hover {
		      background-color: darken($brand-color-1, 20);
		    }
			&:disabled {
			  background-color: lighten($brand-color-1, 20);
			  &:hover {
			    background-color: lighten($brand-color-1, 20);
			  }
			  cursor: not-allowed;
			}    
		}
	}
	&__content{
		margin-bottom: $base-padding*0.5;
		
		&_module {
			margin-bottom: 2rem;
			.module {
				&__header {
					color: $gray-base;
					background-color: $brand-color-4;
					font-size: 1rem;
					padding: 0.75rem;
				}
				&__content {
					margin: 0.5rem;
					&_index-header {
						width:5%;
						float : left;
						margin-top: 1rem;
					}
					&_index-value {
						width:5%;
						float : left;
						margin-top: 1rem;
						color: $brand-color-2;
					}
					&_title-header {
						width:30%;
			            float : left;
			            margin-top: 1rem;
			            font-weight: 700;
			   			font-size: 1rem;
					}
					&_title-value {
						width:30%;
			            float : left;
			            margin-top: 1rem;
			            font-weight: 700;
			            color: $brand-color-2;
			   			font-size: 1rem;
					}
					&_header {
						width:14%;
						float : left;
						text-align: center;
						font-weight: 700;
			    		font-size: 1rem;
			    		background-color: $brand-color-4;
			    		padding: 0.5rem;
			    		margin: 0.5rem;
					}
					&_value {
						width:14%;
						float : left;
						text-align: center;
						margin-top: 1rem;
						font-weight: 700;
			    		font-size: 1rem;
			    		padding: 0.5rem;
			    		margin: 0.5rem;
					}
					&_description{
						width: 55%;
					    background: none;
					    font-size: .875rem;
					    border: 1px solid #d0d0d0;
					}
				}
			}
		}
	}	
	
	.radio {
     -webkit-appearance: none;
     -moz-appearance: none;
     appearance: none;
     display: inline-block;
     position: relative;
     background-color: #f1f1f1;
     border: 1px solid $brand-color-18;
     color: #666;
     height: 20px;
     width: 20px;
     border-radius: 50px;
     cursor: pointer;     
     outline: none;
	}
	.radio:checked::before {
     position: absolute;
     font: 13px/1 'Open Sans', sans-serif;
     left: 6px;
     top: 3px;
     content: '\02143';
     transform: rotate(40deg);
	}
	.radio:hover {
     background-color: #f7f7f7;
	}
	.radio:checked {
     background-color: #666;
     color: #f1f1f1;
	}
	.radio:disabled {
	  cursor: not-allowed;
	}      
}


