// @mixin make-row {
//   @include clearfix;
// }

@mixin make-block_private($gutter: $grid-padding) {
  float: left;
  padding-left:  ($gutter * 0.5);
  padding-right: ($gutter * 0.5);
  min-height: 1px;
}

@mixin make-one-full_private() {
  @include make-block;
  width: 100%;
}
@mixin make-one-half_private() {
  @include make-block;
  width: 50%;
}
@mixin make-one-third_private() {
  @include make-block;
  width: 33.333%;
}

@mixin make-two-third_private() {
  @include make-block;
  width: 66.666%;
}
@mixin make-one-fourth_private() {
  @include make-block;
  width: 25%;
}
@mixin make-one-fifth_private() {
  @include make-block;
  width: 20%;
}
@mixin make-one-sixth() {
  @include make-block;
  width: 16.66%; 

}
@mixin make-three-fifth_private() {
  @include make-block;
  width:  60%;
}

// Extra grid classes
@mixin make-three-fourth_private {
  @include make-block;
  width: 75%;
}
@mixin make-four-fifth_private {
  @include make-block;
  width: 80%;
}
@mixin make-two-fifth_private {
  @include make-block;
  width: 40%;
}

@mixin make-one-sixth_private {
  @include make-block;
  width: 16.6666666667%;
}
@mixin make-five-sixth_private {
  @include make-block;
  width: 83.3333333333%;

}


@mixin make-three-tenth_private {
	@include make-block;
	width: 30%;
}

@mixin make-one-tenth_private {
  @include make-block;
  width: 10%;
}

@mixin make-third-of-twentyth_private {
  @include make-block;
  width: 15%;

}
@mixin  make-block($gutter: $grid-padding) { 
	@include make-block_private($gutter: $grid-padding); 
}
@mixin  make-one-full() { 
	@include make-one-full_private(); 
}
@mixin  make-one-half() { 
	@include make-one-half_private(); 
}
@mixin  make-one-third() { 
	@include make-one-third_private(); 
}
@mixin  make-two-third() { 
	@include make-two-third_private(); 
}
@mixin  make-one-fourth() { 
	@include make-one-fourth_private(); 
}
@mixin  make-one-fifth() { 
	@include make-one-fifth_private(); 
}
@mixin  make-three-fifth() { 
	@include make-three-fifth_private(); 
}
@mixin  make-three-fourth { 
	@include make-three-fourth_private; 
}
@mixin  make-four-fifth { 
	@include make-four-fifth_private; 
}
@mixin  make-two-fifth { 
	@include make-two-fifth_private; 
}
@mixin  make-one-sixth { 
	@include make-one-sixth_private; 
}
@mixin  make-five-sixth { 
	@include make-five-sixth_private; 
}
@mixin  make-three-tenth { 
	@include make-three-tenth_private; 
}
@mixin  make-one-tenth { 
	@include make-one-tenth_private; 
}
@mixin  make-third-of-twentyth { 
	@include make-third-of-twentyth_private; 
}