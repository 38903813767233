.package-confirm-form {
	@include create-transition-form;
	&__transition-form {
		textarea {
		    width: 100%;
		}
	}
	&__control-buttons {
		display: inline-table;
	}
	&__hotel-info {
		padding-top: 0.25rem;
    	padding-bottom: 0.25rem;
    	text-align: center;
    	&_header-row , &_row-content {
    		content: "";
    		display: table;
    		width:100%;
    		
    		.hotel-city-name, .hotel-country-name,.room-type, .no-of-night, .stay-at {
    			float:left;
    			padding: 0.25rem;
    		}
    		.hotel-city-name, .hotel-country-name,.room-type {
    			width:20%;
    		}
    		.no-of-night {
    			width:15%;
    		}
    		.stay-at {
    			width:25%;
    			> select {
    				padding: 0.375rem 0.75rem;
    				background-color: $light;
    				border-radius: 0.25rem;
    				width: 100%;
    			}
    			&__alt-hotel-unavailable {
    				color:$brand-color-1;
    				font-size: 0.725rem;
    			}
    		}
    	}
		&_header-row {
			background-color:$brand-color-4;
			text-transform: uppercase;
			margin-bottom: 0.625rem;
		}
		
		&_row-content {
			margin-bottom: 0.625rem;
		}
	}
	&__field {
		width:50%;
		float:left;
		margin-left: 0px;
		padding-left: 0.4rem;
    	padding-right: 0.4rem;
		&_value {
			width:100%;
			padding: .625rem 1rem;
		}
	}
	
}