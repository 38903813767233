.transfer {
	&__booking-details {
		&_container {
			@include clearfix;
			width:100%;
			
			.transfer-basic-info {
				font-size: 1rem;
				&__transfer-name {
					font-size: 2rem;
					color: $brand-color-2;
				}
			}
			.booking-details {
				&__pickup-dropoff {
					width:45%;
					float:left;
					&_title{
						color: $brand-color-2;
						font-size: 1.15rem; 
					}
					&_value{
						span {
							margin-right:0.5rem;
						}
					}
				}
				&__separator{
					width: 10%;
					float: left;
					margin-top: 2rem;
				} 
			}
		}
	}
}

.booking-product {
	&__transfer-overview {
		@include clearfix;
		width : 100%;
		&_transfer-details, &_transfer-duration {
			float : left;
			width: 50%;
		}
		&_transfer-duration{
			margin-top: 0.5rem;
		}
	}
}