.rfq-exception-details {
	padding: 0.725rem;
	border: 10px solid $brand-color-3;
	margin-bottom: 0.725rem;
	font-family: $font-family-number;
	
	&__heading-container {
		@include clearfix;
		
		&_heading, &_close-button {
			float: left;
	   		font-size: 1.25rem;
		    border-bottom: 1px solid $brand-color-3;
		    padding-bottom: 0.5rem;
		    margin-bottom: 0.5rem;
		}
		
		&_heading {
		    width: 90%;
		    color: $brand-color-2;
		    text-align: center;
		}
		
		&_close-button {
			width: 10%;
			text-align: right;
			cursor: pointer;
		}
	}
	
	&__details {
		&_info-container {
			@include clearfix;
			padding: 0.125rem;
			
			.info-container {
				@include clearfix;
				margin-bottom: 0.25rem;
			
				&__activity-container {
					@include clearfix;
					width: 100%;
					font-size: 1rem;
					color: $brand-color-32;
					
					&_activity-data {
						@include clearfix;
						float: left;
						width: 100%;
						
						.activity-data {
							&__updated-by-label, &__updated-by-value, &__updated-by-separator, &__activity-date-value {
								margin-right: 0.25rem;
								color: $brand-color-2;
							}
														
							&__updated-by-value, &__activity-date-value, &__updated-on-value {
								color: $brand-color-32;
								font-weight: bold;
							}
						}
					}
					
				}
				
				&__product-details {
					@include clearfix;
					float: right;
					width: 70%;
					&_heading-container, &_value-container {
						font-size: 0.9375rem;
						color: $brand-color-2;
						.heading-container, .value-container {
							&__status, &__date, &__departure, &__product, &__amount {
								width: 20%;
								float: left;
								min-height: 1px;
								font-weight: bold;
								padding-right: 1rem;
							}
						}
					}
					&_value-container {
    					word-wrap: break-word;
						color: $brand-color-5;
						font-weight: 600;
						.value-container {
							&__amount {
								color:$brand-color-14;
							}
						}
					}
					
				}
				
			}
		}
		&_loader{
			text-align:center;
		}
	}
}