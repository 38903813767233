.service-tag-display {
  padding: 0.725rem;
  border: 1.25rem solid $brand-color-3;
  margin-bottom: 0.725rem;
  font-family: $font-family-number;

  &__container {
    &_footer-btn {
      .save-btn,
      .close-btn {
        display: table;
        content: "";
        padding: 0.625rem 8rem 0.625rem 8rem;
        background: $brand-color-1;
        color: $brand-color-23;
        margin-right: 0.635rem;
        cursor: pointer;

        &:hover {
          color: $brand-color-23;
          text-decoration: none;
          background-color: $brand-color-2;
        }
      }
      .save-btn {
        float: left;
      }
      .save-btn-disabled {
        cursor: not-allowed;
        opacity: 0.65;
        box-shadow: none;
      }
    }

    &_content {
      padding-bottom: 1rem;
    }

    &_header {
      .service-tag-header,
      .service-tag-header-close-btn {
        font-size: 1.25rem;
        border-bottom: 0.25rem solid $brand-color-3;
        padding-bottom: 0.5rem;
        margin-bottom: 0.5rem;
        color: $brand-color-2;
      }
      .service-tag-header {
        width: 90%;
        float: left;
        text-align: center;
      }
      .service-tag-header-close-btn {
        width: 10%;
        float: right;
        text-align: right;
        cursor: pointer;
      }
    }
    .custom-header {
      @include clearfix();
      font-size: 0.875rem;
      &__mandatory-icon {
        @include clearfix();
        font-size: 0.5rem;

        color: $brand-color-1;
      }
      &__title {
        color: $sf-label-color;
        float: left;
      }
    }
  }

  .form-field-validation-error {
    width: 100%;
    margin-bottom: 1rem;
    color: $brand-color-1;
  }
}
