.document-upload-container{
	padding: 0.725rem;
	border: 10px solid $brand-color-3;
	margin-bottom: 0.725rem;
	
	&__heading {
	    color: #087dc2;
	    font-size: 1.25rem;
	    text-align: center;
	    border-bottom: 1px solid #c7e5f6;
	    padding-bottom: .5rem;
	    margin-bottom: .5rem;
	}
	
	&__file-container {
		@include clearfix;
		padding-left: 0.725rem;
		width: 100%;
		color: #087dc2;
		
		&_header {
			width:100%;
		}
		&_files {
			color: #087dc2;
			width:33%;
			padding-left: 0.725rem;
			padding-bottom: 0.725rem;
		    float: left;
			.files__name {
				float:left;
			}
		}
	}
	
	&__form {
				padding: .725rem;
				color: #087dc2;
				&_value {
					.value {
						&__file-input{
						}
						&__label {
							width : 100%;
						}
					}
				}
				&_field {
					padding: .5rem;
     				width : 100%;
					.field {
						&__control-buttons {
							@include clearfix;
							width: 100%;
							margin: 0 auto;
							&_send-email{
								@include make-button($light, $brand-color-2, $light, darken($brand-color-2,20));
								padding : .3125rem;
								font-weight: 700;
								width: 15%;
								float: right;
								margin-right: .3125rem;
							}
						}
					}
					}}
}
