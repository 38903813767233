.pnr-details {
	&__details-block {
		font-size: 0.875rem;
		@include clearfix;
		width: 100%;
		
		&_title {
			float: left;
			color: $brand-color-2;
		}
		&_separator {
			float: left;
			color: $brand-color-2;
			margin-left: .25rem;
			margin-right: .25rem;
		}
		&_value {
			float: left;
		}
		&_ttl-flag{
			color: $brand-color-1;
		}
		&_ttl-remark{
			display: inline-block;
			margin-left: .3rem;
		}
	}
	
	&__extra-info-icon {
  		font-size: 1rem;
  		color: $brand-color-2;
  		padding-left: 0.5rem;
	}
	
	&__flexi-info-icon {
    	font-size: 1.3rem;	
    	color: $brand-color-2;
    	padding-left: 1rem;
	}
	
}
.air-port-message-info-icon {
  font-size: 1rem;
  color: #ec2227;
  padding-left: 0.5rem;
}

.air-port-message-modal {
  &__mesagge {
    color: #fff;
    background-color: #087dc2;
    @include no-border;
    font-size: $font-size-h5;
    font-family: $font-family-base;
    padding: 7px;
  }
  &__notes-pointer {
    font-size: 8px;
    color: #000000;
  }

  &__text {
    font-size: $font-size-h5;
    font-family: $font-family-base;
    color: #000000d4;
    line-height: 3;
  }
}

.fa-circle {
  font-size: 8px;
  color: #000000;
}

.modal-open {
  .air-port-message-modal.modal {
    overflow-x: hidden;
    overflow-y: hidden;
    width: auto;
    height: auto;
  }
}
