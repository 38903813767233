.update-lpo-number{
	@include make-input;
	padding: 0.725rem;
	border: 0.625rem solid $brand-color-3;
	margin-bottom: 0.725rem;
	
	&__heading {
	    color: $brand-color-2;
	    font-size: 1.25rem;
	    text-align: center;
	    border-bottom: 1px solid $brand-color-3;
	    padding-bottom: .5rem;
	    margin-bottom: .5rem;
	    text-transform: uppercase;
	}
	&__list {
		border: 0.063rem solid;
		&_header {
			@include clearfix;
			background-color:  $brand-color-2;
			.update-lpo-number-list-header {
				color:  $brand-color-3;
				text-align: center;
				font-weight: 700;
				text-transform: uppercase;
			}
		}
		&_content {
			box-shadow: $brand-color-22 2.4px 2.4px 3.2px;
			display: table;
			width: 100%;
		}
		&_header > .update-lpo-number-list-header,
		&_content > .update-lpo-number-list-content {
			@include clearfix;
			float: left;
			font-size: 0.875rem;
			padding: 0.5rem;
			width: 33.33%;
			word-break: break-word;
			text-align: left;
			height: 3.675rem;
			border-right: 0.063rem solid;
		}
	}
	&__control-buttons {
		text-align: center;
		padding-bottom: 3rem;
		&_cancel,&_save {
			@include make-button($light, $row-book-button-bgcolor, $light, darken($row-book-button-state,20));
			float : left;
			margin-left: 0.5rem;
			margin-right: 0.125rem;
			margin-top: 0.525rem;
			padding: 0.525rem;
			width: 30%;
		}
	}
	&__form-field-validation-error{
	    font-size: 0.825rem;
    	line-height: 1rem;
    	color: $brand-color-1;
    	padding-top: 0.188rem;		
	} 
}