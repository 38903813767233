@mixin create-main-wrapper_private {
  &_main {
    @include make-row;
    margin-left: -$base-margin*0.5;
    margin-right: -$base-margin*0.5;
    padding-top: $base-padding;
    .content {
      padding-left: 0;
    }
  }
  &_wrapper {
    @include make-responsive-width('max-width',$sm-width,$md-width,$lg-width);
    margin: 0 auto;
    @content;
    .single-content{
    @include make-one-full;
    }
  }
}

@mixin  create-main-wrapper { 
	@include create-main-wrapper_private; 
}

@media print{
  .search__result_wrapper{
    display: none;
  }
}