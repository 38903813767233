.air-fare-details {
	@include clearfix;
	width: 100%;
	font-size: 0.875rem;
	font-family: $font-family-number;
	
	&__title {
		font-size: 1.15rem;
    	color: $brand-color-2;	
	}
	
	&__content {
		@include clearfix;
		padding: 0.5rem 1rem;
		border: 2px solid #eceeef;
		
		&_amount-container {
			@include clearfix;
			width: 100%;
			
			.amount-container {
				&__title {
					float: left;
					color: $brand-color-2;	
					width: 60%;
				}
				&__value{
					float: right;
					font-family: $font-family-number;
				}
			}
		}
		
		&_pax-fare-details {
			margin-top: 0.5rem;
			&:first-child {
				margin-top: 0;
			}
		
			.pax-fare-details {
				&__separator {
					border-bottom: 2px solid #c7e5f6;
					width: 100%;
				}
			}
		}
	}
	
	&__total-fare-details {
    	margin-top: 0.3125rem;
	}
	
	&__tax-details {
    	margin-top: 0.3125rem;
	}
}