@mixin create-transition-form_private (){
	&__transition-form {
		&_form {
			padding: 0.725rem;
			border: 10px solid $brand-color-3;
			margin-bottom: 0.725rem;
		}
	
		textarea {
		    width: 50%;
			background: none;
			font-size: 0.875rem;
			vertical-align: middle;
			line-height: normal;
			background-color: $input-bgcolor;
			border: 1px solid;
			border-color: $input-border-color;
		}
	}
	&__heading {
   		color: $brand-color-2;		
   		font-size: 1.25rem;
	    text-align: center;
	    border-bottom: 1px solid $brand-color-3;
	    padding-bottom: 0.5rem;
	    margin-bottom: 0.5rem;
	    &_cancellation-message{
		color:$cancelled-text-color;
		font-size: $font-size-h4;
		text-align: left;
    		font-weight: 900;	
	    }
   			
	}
	&__field {
		width: 100%;
		margin-left: 0.5rem;
		margin-bottom: 0.5rem;
		&_label{
			width:100%;
			margin-bottom: 0.25rem;
	   		color: $sf-label-color;
	   		font-size: 0.875rem;			
	   		&-row{
	   			width: auto;
	   			padding-right: 2rem;	
	   		}
		}
		&_remark{
			width:100%;
			float:left;
			&-text-area{
				width:50%;
				float:left;
			}
		}	
		&_value{
			width: 50%;
			padding: 0.5rem 1rem;
			background: none;
			font-size: 0.875rem;
			vertical-align: middle;
			line-height: normal;
			background-color: $input-bgcolor;
			border: 1px solid;
			border-color: $input-border-color;
			position: relative;
		}
		&_select-value{
			width: 50%;
			background: none;
			font-size: 0.875rem;
			vertical-align: middle;
			line-height: normal;
			background-color: $input-bgcolor;
			border-color: $input-border-color;
			position: relative;
			select {
				display: block;
			    width: 100%;
			    height: 34px;
			    padding: 6px 12px;
			    font-size: 0.875rem;
			    line-height: 1.428571429;
			    background-image: none;
			    background-color: $light;
			    border-radius: 4px;
			    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
			    transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
			}
		}
		&_info-message {
			color: $brand-color-2;
			font-size: .875rem;
    		font-weight: 700;
		}
		.disabled{
			background-color: $gray-lightest
		}
		&_validation-error{
			color:red;
		}
		&_warning-message{
			color:$brand-color-9;
			font-size: $font-size-h4;
		}
		&_pnr-cancellation-warning-message{
			text-align: left;
  			color: $brand-color-9;
    		font-size: 1.1rem;
    		font-weight: bold;
		}
		&_pnr-multi-tst-cancellation-warning-message{
			text-align: left;
  			color: $brand-color-9;
    		font-size: 0.875rem;
		}
		&_data-block {
			@include clearfix;
			width: 100%;
		}
		&_data {
			float: left;
			margin-right: .25rem;
		}
		&-row{
			display: flex;	
		}
	}
	&__input-group {
		@include clearfix;
		width: 85%;
	}
	&__input-group-addon {
		white-space: nowrap;
	    vertical-align: middle;
	    padding: 9px 12px;
	    font-size: 14px;
	    font-weight: 400;
	    line-height: 1;
	    text-align: center;
	    border: 1px solid #ccc;
	    float: left;
    }	
	&__control-buttons {
		@include clearfix;
		width: 100%;
		margin: 0 auto;
		.control-buttons {
			&__confirm-cancel, &__close, &__revert_reissue, &__save, &__update-to-all {
				@include make-button($light, $row-book-button-bgcolor, $light, darken($row-book-button-state,20));
				float: left;
				margin-left: 0.5rem;
	    		margin-right: 0.125rem;
	    		margin-top: 0.525rem;
				padding: 0.525rem;
				width: 30%;
			}
		}
	}
}

@mixin  create-transition-form (){ 
	@include create-transition-form_private(); 
}