.profile{
  background: $color-background-grey;
  border-radius: $pixel-unit-m;
  position: absolute;
  box-shadow: $pixel-unit-s $pixel-unit-s $pixel-unit-xs $color-label-grey;
  z-index: 2;
 &__header{
  &_caption{
  background: $color-black;
  color: $color-white-text;
  padding: $pixel-unit-l;
  display: inline-block;
  border-top-left-radius: $pixel-unit-m;
  border-bottom-left-radius: $pixel-unit-m;
}
  &_btn{
    display: inline-block;
    float: right;
    button{
      background: $color-blue-btn;
      color: $color-white-text;
      border: solid $pixel-unit-o;
      padding: $pixel-unit-m;
      margin: $pixel-unit-m;
      &:hover,
      &:focus{
        background: $color-blue-highlight-btn;
      }
    }
  }
}

&__my-profile{
      border-bottom:solid $pixel-unit-xs #b0b7bd;
      padding: $pixel-unit-m;
      display: table-cell;
      padding: 8px;
      &:hover,
      &:focus{
        background: $color-highlight-grey;
      }
    }
  &__list{
    list-style: none;
    padding: $pixel-unit-m;
    display: table-cell;
    float: left;
    &_profile{
    border-bottom:solid $pixel-unit-xs #b0b7bd;
    padding: 8px;
    &:hover,
      &:focus{
        background: $color-highlight-grey;
      }
    }
  }
  
  &__detail-view , &__add-view{
  	display: table-cell;
    float: right;
    padding: $pixel-unit-m;
  }
}

.profile{
	&__self-tag{
		    padding: 1px;
    background: #ECEEEF;
    border-radius: 5px;
    display: inline-block;
    float: right;
    margin-right: 10px;
    margin-left: 20px;
	}
	&__chevron{
	display: inline-block;
    float: right;
	}
  &__contact-details-text{
  	
  }
  &__contact-details{
  
  }
}

.clear{
	clear: both;
}
.date-of-birth{
     width: 20%; 
}
.add-view{
display: inline-block;
&__general-info{
}
}
.row{
display: block;
}
.group{
display: table-cell;
}

.add-button{
padding: 3px;
    background: #007f00;
    color: #fff;
    border-radius: 10PX;
    margin-left: 5px;
    }
