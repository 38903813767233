.input-vat-details{
	@include clearfix;
	padding: 0.5rem 1rem;
	border: 0.125rem solid $gray-lighter;
	&__price-row-label{
		float: left;
    	width: 50%;
    	font-size: 1.25rem;
	}
	&__price-row-container{
		float: left;
    	width: 50%;
    	&_price-container{
    		float: right;
    		padding-right: 2rem;
    		.price-container{
				&__currency {
					font-size: 0.875rem;
					float: left;
					margin-right: 0.3125rem;
			   		margin-top: 0.375rem;
				}
				
				&__amount {
					float: left;
					font-size: 1.25rem;
					color: $brand-color-1;
					font-weight: 500;
					font-family: $font-family-number;
				}
			}
		}
		
	}
}