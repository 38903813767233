.ticket-product-form {
	@include create-transition-form;
	
	&__transition-form_form {
		float: left;
	}
	
	&__control-buttons {
		float: left;
		margin-top: 1rem;	
	}
		 
	&__info {
		@include clearfix;
		float: left;
		width: 100%;
		padding-left: 0.5rem;
		padding-right: 0.5rem;
		
		&_pax-container {
			@include clearfix;
			font-size: 0.875rem;
			float: left;
			width: 100%;
			
			.pax-container {
				&__pax-ticket-info {
					@include clearfix;
					float: left;
					width: 50%;
					
					&_label {
						color: $brand-color-2;
					}
					&_value {
						> input {
							width: 100%;
	    					border: 1px solid $input-border-color;
							padding: 0.25rem 0.5rem;
						}
					}
					
				}
			
				&__pax-basic-info {
					@include clearfix;
					float: left;
					width: 50%;
					
					&_name {
						font-size: 1.15rem;
						margin-top: 1rem;
						color: $brand-color-2;
					}
				}
			}
		}
		
		&_sector-container {
			@include clearfix;
			font-size: 0.875rem;
			float: left;
			width: 100%;
			margin-top: 0.5rem;
			
			.sector-container {
				&__details {
					&_headings {
						background-color: $light-gray;
					}
					
					&_headings, &_values {
						@include clearfix;
						width: 100%;
						padding: 0.25rem;
						
						.headings, .values {
							&__sector {
								float: left;
								width: 25%;
							}
							&__airline-name {
								@include clearfix;
								float: left;
								width: 15%;
								
								&_airline-code, &_separator, &_flight-number {
									float: left;
								}
								&_separator {
									margin-left: 0.25rem;
									margin-right: 0.25rem;
								}
							}
							&__duration {
								float: left;
								width: 40%;		
							}
							&__status {
								float: left;
								width: 20%;		
							}
							
							&__sector {
								@include clearfix;
								
								&_from-iata, &_to-iata, &_separator {
									float: left;
								}
								&_from-iata {
									width: 25%;
								}
								&_separator {
									width: 20%;
								}
							}
							
							&__duration {
								@include clearfix;
								
								&_departure-date, &_separator, &_arrival-date {
									float: left;
								}
								&_separator {
									margin-left: 0.25rem;
									margin-right: 0.25rem;
								}
							}
						}
					}
				}
			}
		}
		
		&_separator {
			border-bottom: 2px solid $brand-color-3;
			display: table;
			width: 100%;
			padding-top: 1rem;
			margin-bottom: 2rem;
		}
	}
	
	&__remarks {
		float: left;
		width: 100%;
		padding-left: 0.5rem;
		padding-right: 0.5rem;
		margin-top: 1rem;
		font-size: 0.875rem;
		
		&_title {
			color: $brand-color-2;
		}
	}
	
	&__supplier {
		float: left;
		width: 100%;
		padding-left: 0.5rem;
		padding-right: 0.5rem;
		margin-top: 1rem;
		font-size: 0.875rem;
		
		&_title {
			color: $brand-color-2;
		}
		&_value {
		    width:50%;
			>select {
			    width:100%;
				padding: .5rem 1rem;
			    background: 0 0;
			    font-size: .875rem;
			    vertical-align: middle;
			    line-height: normal;
			}
		}
	}
	
	&__pnr-container {
		@include clearfix;
		float: left;
		width: 100%;
		margin-left: 0.5rem;
		font-size: 0.875rem;
		
		&_label, &_value, &_separator {
			float: left;
		}
		
		&_label {
			color: $brand-color-2;
		}
		
		&_separator {
			margin-left: 0.25rem;
			margin-right: 0.25rem;
		}
	}	
	
}