.sightseeing-price-breakup {
	@include clearfix;
	width: 100%;
	font-size: $font-size-h4;
	font-family: $font-family-number;	
	
	&__title {
		font-size: 1.15rem;
    	color: $brand-color-2;	
	}
	
	&__content {
		@include clearfix;
		padding: 0.5rem 1.125rem;
		border: 2px solid $gray-lighter;
		
		&_amount-container {
			@include clearfix;
			width: 100%;
			
			.amount-container {
				&__title {
					float: left;
					color: $brand-color-2;	
					width: 60%;
				}
				&__value{
					float: right;
					font-family: $font-family-number;
				}
			}
		}
		
		&_pax-price-details {
			margin-top: 0.5rem;
			&:first-child {
				margin-top: 0;
			}
		
			.pax-price-details {
				&__separator {
					border-bottom: 2px solid $brand-color-3;
					width: 100%;
				}
			}
		}
	}
	
	&__total-price-details {
    	margin-top: 0.3125rem;
	}
}