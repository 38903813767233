.my-dependants {
	@include create-main-wrapper;
	@include create-row-card;
	@include create-row-card-header;
	@include create-card-content;
	@include clearfix;
	//max-width: $screen-sm-max;
	margin: 0 auto;
	margin-top: 30px;
	
	.my-profile__page_main {
		padding-top: 0;
	}
	
	label {
    	margin-bottom: 0;
    	color: $sf-label-color;
    	font-size: 0.875rem;
  	}
  	
  	&__sidebar {
  		width: 25%;
  		float: left;
  		padding: .5px 10px;
  		padding-bottom: 0.625rem;
	    background-color: #fff;
	    box-shadow: 0 0 15px rgba(0,0,0,.25);
  	}
  	
  	&__content {
  		width: 75%;
  		float: left;
  		min-height: 1px;
  		padding-left:10px;
  	}
	
	&__alert, &__success {
		margin-left: 0.625rem;
	}
	
	&__no-list-message {
		padding: 10px;
		width: 100%;
		font-size: 20px;
		margin: 0 0 -10px 10px;
		background-color: $light;
		text-align: center;
	}
	
	&__add-new-dependant-wrapper {
	    padding: .9375rem;
	    background-color: #fff;
	    box-shadow: 0 0 15px rgba(0,0,0,.25);
	    width: 100%;
	    margin-bottom: 0.9375rem;
	    
	    &_save-button {
	    	@include clearfix;
		    margin-bottom: 0.725rem;
		    
			>button {
				float: right;
				font-size: 20px;
				color: $light;
				background-color: $brand-color-1;
				text-transform: uppercase;
				width: 50%;		
			}
		}
	    
	    &_gender-container, &_pax-details-container {
	    	@include clearfix;
		    margin-bottom: 0.725rem;
		    
		    .pax-details-container {
		    	&__title, &__first-name, &__last-name {
	    			float: left;
	    			padding: 5px;	 
	    			
	    			&_label, &_value {
	    				width: 100%;
	    			}   
					
					&_value {
						height: 34px;
					}	
					
					>select {
						background: 0 0;
					}
					
		    	}
		    	
    			&__title {
    				width: 20%;
    			}
    			
    			&__first-name, &__last-name {
    				width: 40%;
    			}
	    			
		    	
		    	&__date-of-birth, &__place-of-birth, &__nationality, &__source-media-code, &__source-media-code-info, &__place-of-birth {
		    		float: left;
		    		padding: 5px;
		    		width: 50%;
		    	}
		    	
		    	&__place-of-birth {
		    		&_label, &_value {
	    				width: 100%;
	    			} 
	    			
	    			&_value {
						height: 34px;
					}	
	    		}
		    	
		    	&__relation {
	    			float: left;
		    		padding: 5px;
		    		width: 50%;
		    		
		    		&_label, &_value {
	    				width: 100%;
	    			}   
					
					&_value {
						height: 34px;
					}
					
					>select {
						background: 0 0;
					}
		    	}
		    	
		    	&__date-of-birth {
			    	.input-group {
			    		>input {
			    			height: 34px;
			    			padding: 0.5rem 1rem;
			    		}
			    	}
		    	
		    	}
		    	
		    	&__nationality {
		    		.country-selector__wrapper {
		    			width: 100%;
	    				padding: 0;
		    			>select {
		    				width: 100%;
		    				background: 0 0;
		    				height: 34px;
		    			}
		    		}
		    	}
		    	&__source-media-code-info {
        			>textarea {
        				height: 64px;
        			}
        		}
		    	
		    }
		    .gender-container__wrapper {
		    	margin-left: 0.325rem;
		    	&-radio{
		    		margin-right: 1rem;
		    	}
		   	}
		    .gender-container__label {
		    	width: 0;
		    }
		    
	    }
	}
	
	&__header {
		@include clearfix;
		height: 62px;
		
		&_dependent-list-title {
	  		float: left;
	  		width: 25%;
		    font-size: 1.875rem;
		    margin-top: 0.9375rem;
	  	}
	  	&_dependent-alert-messages {
	  		float: left;
	  		width: 75%;
	  	}
		
		&_btn {
			>button {
				background: #127dce;
			    color: #fff;
			    border: 0 solid;
			    padding: 5px;
			    margin: 10px 0;
			    width: 100%;
			}
		}	
	}
	
	input[type="text"],
	input[type="email"],
	input[type="password"] {
	    width: 100%;
	    padding: 0.5rem 1rem;
	    background: none;
	    font-size: 0.875rem;
	    vertical-align: middle;
	    line-height: normal;
	    background-color: $input-bgcolor;
	    border: 1px solid;
	    border-color: $input-border-color;
	    max-height: 34px;
	}
	
	select, textarea {
	    width: 100%;
	    padding: 0.5rem 1rem;
	    background: none;
	    font-size: 0.875rem;
	    vertical-align: middle;
	    line-height: normal;
	    background-color: $input-bgcolor;
	    border: 1px solid;
	    border-color: $input-border-color;
	    max-height: 34px;
	}

	textarea {
	    max-height: 64px;
	}
}

.my-dependants__list {

	&_dependent_list-wrapper {
		@include clearfix;
	}
		
	&_dependent-headings {
		@include clearfix;
		background-color: #f0f5f8;
    	margin-bottom: .9375rem;
	}
	
	&_save-button {
    	@include clearfix;
	    margin-bottom: 0.725rem;
	    
		>button {
			float: right;
			font-size: 20px;
			color: $light;
			background-color: $brand-color-1;		
		}
	}
	
}

.dependent-headings {
	&__dependent-name, &__dependent-relation, &__dependent-is-active, &__dependent-edit, &__dependent-details {
		float: left;
	}
}

.dependent-list-wrapper {
	&__dependent-headings-active {
		@include clearfix;
		cursor: pointer;
		background-color:$gray-lighter;
		padding:0.625rem;
	}

	&__dependent-headings {
		@include clearfix;
		padding:0.625rem;
		cursor: pointer;
		
		&_dependent-name, &_dependent-relation, &_dependent-is-active, &_dependent-edit {
			float: left;
			padding-left: 0;
			padding-right: 0;
		}
		
		&_dependent-name {
			@include clearfix;
			overflow: hidden;
		    white-space: nowrap;
		    text-overflow: ellipsis;	
		    width: 95%;
		    display: inline-block;
		    font-size: 15px;		
		}
	}
	
	&__dependent-details {
		margin-left: 0.625rem;
	}
}
.dependent-name__icon{
	    display: inline-block;
    	width: 5%;
    	padding-top:0.25rem;
}