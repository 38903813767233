.miscellaneous-transaction-details {

	&__content {
		font-size: 1rem;
		&_title {
			color: $brand-color-2;
		}
	}
	
	&__dynamic-form-content{
			padding-top: 1rem;
			font-size: 1rem;
	}
	
	.required:after {
                content:'';
     }		
	
	.dynamic-form{
		@include dynamic-form; 
		input[type=text], input[type=number] {
		    padding: 0;
		    background: none;
		    line-height: normal;
		    border: none;
		}
		textarea{
			padding: 0;
		    background: none;
		    line-height: normal;
		    border: none;
		    resize: none;
		    font-size: .875rem;
		}
		.one_col_layout, .two_col_layout, .three_col_layout, .four_col_layout{
			padding: 0;
		}
		
		.group-form{
			padding-bottom:0;
		}
		
		.search-date_select {
			input[type=text] {
			    width: 100%;
			}
			.input-group-btn{
				display:none;
			}	
		}
		
		.dynamic-text-area-field{
    		padding: .25rem .9375rem;
			 &__value{
    	   	 padding: .25rem .9375rem;
			}
		}
		
		.dynamic-number-field, .dynamic-text-field, .dynamic-single-select, .dynamic-multi-select, .dynamic-date-field, .dynamic-date-range-field, .dynamic-number-field, .dynamic-time-field{
			float: left;
    		padding: .25rem .9375rem;
   	 		min-height: 1px;
		}
		
		.dynamic-text-field {
			width : 100%;
			
			&__value{
    	    	padding-right: 0.5rem;
   	    	}
		}
		.dynamic-date-field{
			.date-field__label{
				color: $brand-color-2;
				font-size: .875rem;	 
				font-weight: 400;
			}
		}
		
		.dynamic-single-select {
			select {
				appearance: none;
	    		border: 0;
	    		padding: 0;
			}
		}
		
	}
}
 .ancillary-details{
	 width: 100%;
	 &__header{
		background-color: $brand-color-3;
		color: $brand-color-2;
		font-size: 1.25rem;
		padding-left: 0.5rem;
		width: 100%;
		font-weight: 500;
	 }

	 &__container{
		 @include clearfix;
		width: 100%;
		border: 2px solid $brand-color-3;
		padding: 0.5rem;

		.ancillary-type,
		.ancillary-code,
		.ancillary-discription {
			padding: 0.313rem 0.9375rem;
			float: left;

			&__label{
				color: $brand-color-2;
				font-size: .875rem;	 
				font-weight: 400;
			}
		}

		.ancillary-type{
			width: 30%;
		}
		.ancillary-code{
			width: 40%;
		}
		.ancillary-discription{
			width: 30%;
		}
	 }
 }

