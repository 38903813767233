.insurance {
	&__booking {
		&_duration-start, &_duration-end, &_duration-icon {
			font-size: 0.875rem; 
			float: left;
			width: 40%;
			padding-top: 0.375rem;
		}
		&_duration-icon {
			width: 20%; 
			text-align: center;
		}
		&_duration-end {
			text-align: right;
		}
	}
}