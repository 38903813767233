.hotel{
	&__booking {
		&_duration-check-in, &_duration-check-out, &_duration-icon {
			font-size: 0.875rem; 
			float: left;
			width: 40%;
			padding-top: 0.375rem;
		}
		&_duration-icon {
			width: 20%; 
			text-align: center;
		}
		&_duration-check-out {
			text-align: right;
		}
	}
}