@use "sass:math";

.amendment-policy-info{
	background-color: $brand-color-4;
    border: 2px solid $brand-color-3;
    padding: .5rem;
    &__title{
    	color: $brand-color-2;
    	font-size: $font-size-h3;
    }
    &__content{
	    padding-bottom: math.div($base-padding, 3);
	    font-size: $font-size-h4;
    }
}