.air-lcc-service {
    &__transition-form {
    	&_form {
    		.transition-form {
		        &__close-button {
		            float: right;
		            cursor: pointer;
		        }
		        &__heading-container {
		        	@include clearfix;
		        	width: 100%;
		        	
		            &_heading-title {
		                color: $brand-color-2;
		                font-size: 1.25rem;
		                text-align: center;
		            }
		            &_heading-note {
		            	position: absolute;
		                width: 35%;

		                .note-header{
    						float: left;
    						height: 2rem;
		                }
		                .note-value{
    						float: left;
		                }
		            }
		        }
    		}
    	}
        
        .lcc-sector-details {
            @include clearfix;
            &__header-container {
                @include clearfix;
                background-color: $brand-color-3;
                width: 100%;
                &_title {
                    width: 14%;
                    padding: 0.5rem;
                    float: left;
                    white-space: nowrap;
                }
            }
            &__value-container {
                @include clearfix;
                width: 100%;
                &_value {
                    background-color: $light;
                    width: 14%;
                    padding: 0.5rem;
                    float: left;
                }
                &_doublevalue {
                    background-color: $light;
                    width: 28%;
                    float: left;
                }
                &_mealvalue {
                    width: 50%;
                    padding: 0.5rem;
                    float: left;
                }
                &_mealrow {
                    width: 100%;
                    float: left;
                }
            }
        }
    }
    &__total-ancillary-details {
        margin-top: 0.3125rem;
    }
    &__price-row {
        @include clearfix;

        &_label {
            float: left;
            width: 85%;
            font-size: .8rem;
            color: $brand-color-2;
            padding-left: 7px;
        }
        &_label-expanded {
            color: $gray;
        }
        &_price-container {
            float: left;
            width: 15%;
            text-align: center;

            .price-container {
                &__currency {
                    color: $brand-color-1;
                }
                &__amount {
                    color: $brand-color-1;
                    font-weight: 200;
                }
            }
        }
    }
}
.asterisk{
	color: $asterisk-bgcolor;
    font-size: $asterisk-fontsize;
    vertical-align: text-top;
}
.ancillary-fare-breakup {
	width: 15rem;
}