.air-total-earning-details {
	padding: 0.5rem 1rem;
    border: 2px solid #eceeef;
    
    &__price-row {
    	@include clearfix;
    	
    	&_label, &_price-container {
    		float: left;
    		width: 50%;
    	}
		
		&_label {
			font-size: 1.25rem;
		}
		
		&_label-expanded {
			color: $gray;
		}
		
		&_price-container {
    		float: left;
    		width: 50%;
    		
	    	.price-container {
		    	@include clearfix;
	   			float: right;
	   			
	   			&__currency, &__amount, &__expander {
	   				float: left;
	   			}
	   			
	   			&__currency {
	   				margin-right: 5px;
	   				margin-top: 6px;
	   			}
	   			
	   			&__amount {
	   				font-size: 1.25rem;
	   				color: $brand-color-1;
	   				font-weight: 200;
	   			}
	   			
	   			&__expander {
   					font-size: 1.5rem;
					margin-top: -3px;
					cursor: pointer;
					margin-left: 0.5rem;
					min-width: 25px;
	   			}
	   			
	   			&__expanded {
   					color: $gray;
	   			}
	   		}
    	}
    	    	
    }
    
    &__extra-details {
		@include create-fare-details-extra-content(60%, 2%);
    }
}