@use "sass:math";

@mixin create-search-form_private {


	&__search-form-container {
		background-image: url(../../../images/hero-image-3.jpg);
		padding-top: $base-padding;
		padding-bottom: $base-padding;
		background-size: cover;
		background-position: center left;
		min-height: 100vh;
		@include create-main-wrapper;
	}

	&__search-wrapper {
		@include make-row;
	}

	&__search-form {
		background-color: $sf-bgcolor;
		padding: $base-padding;
		margin: 0 auto;
		min-height: 300px;

		&_header {
			font-size: 1.5rem;
			margin-bottom: .5rem;
			font-weight: 500;
			line-height: 1.1;
			color: inherit;
		}
	}

	&__page-header {
		color: $page-header-color;
		padding-bottom: $base-padding;
		margin-bottom: .5rem;
		font-weight: 500;
		line-height: 1.1;
		font-size: 2.25rem;
		margin-top: 0;
	}

	&__search-trip-type,
	&__search-location,
	&__search-date,
	&__search-traveller,
	&__search-multicity,
	&__search-nationality,
	&__search-residence-country,
	&__search-currency,
	&__additioal-option,
	&__pnr-search-location,
	&__product-selection,
	&__advance-search,
	&__advance-search-params,
	&__search-supplier,
	&__search-modify-journey,
	&__search-category {
		margin-bottom: 1rem;
	}

	&__search-category {
		&_container {
			width: 50%;
			padding-right: 2.125rem;

			.glyphicon-ok {
				width: 8%;
				float: right;
				font-size: 1rem;
				padding-top: 0.25rem;
			}
		}
	}

	&__with-baggage {
		&_label {
			padding: 0.3125rem;
		}
	}

	&__pnr-fields {
		@include make-row;
	}

	&__pnr-search-location {
		.input-container {
			@include make-one-half;
			margin-bottom: 1rem;
		}
	}

	&__search-trip-type,
	&__search-modify-journey {
		@include clearfix;
		background-color: $sf-triptype-bgcolor;
		padding-top: 0.5rem;
		margin-left: -$base-margin;
		margin-right: -$base-margin;
		padding-left: $base-padding*0.5;
		padding-right: $base-padding*0.5;

		.radio-inline {
			@include make-one-sixth;
			@include make-responsive-width('width', auto, percentage(math.div(1, 6)));
			padding-right: 0;

			input[disabled] {
				cursor: not-allowed;
			}
		}
	}

	&__search-modify-journey {
		.modify-journey {
			float: right;
			padding-right: 0.8rem;

			&__button {
				background: transparent;
				font-weight: 600;
			}
		}
	}

	&__search-location {
		@include make-row;

		.search-location_autosuggestion {
			@include make-one-half;
			width: 48%;
		}

		.search-location_exachange-icon {
			width: 3%;
			float: left;
			min-height: 1px;
			padding-top: 1rem;
		}

		.search-location_search-text {
			@include make-one-half;
		}
	}

	&__search-date,
	&__hub-surcharge,
	&__search-date_hotel-dates {
		@include make-row;

		.search-date_select,
		&_baggage-one-way {
			@include make-one-half;

			.input-group-button>button {}
		}

		&_baggage-one-way {
			.baggage-label {
				margin-top: 1.75rem;
				padding: 0.3125rem;
			}
		}

		&_baggage-round-multicity {
			.baggage-label {
				padding: 0.3125rem;
			}
		}

		&_hotel-dates {
			.search-date_select {
				float: left;
				padding-left: .9375rem;
				padding-right: .9375rem;
				min-height: 1px;
				margin-right: -13px;
				width: 35%;
			}
		}

		.date-field-with-duration {
			width: 40%;
		}
	}

	&__search-date-verticle {
		@include make-row;
		width: 50%;
		float: left;
		padding-right: 1rem;
		margin-right: 0px;
		margin-left: 0px;

		.date-range-picker {

			&__from-date,
			&__to-date {
				margin-bottom: 1rem;
			}
		}
	}

	&__hub-surcharge-container {
		@include make-row;
		margin-bottom: 1rem;
	}

	&__search-nationality,
	&__search-residence-country {
		@include make-row;
	}

	&__search-currency {
		@include make-row;
	}

	&__air-select-nationality {
		margin-top: 1rem;
		display: inline-block;
		width: 100%;
	}

	&__search-multicity {

		&-row,
		&_hotel-dates,
		&_hotel-search-date {
			@include make-row;
		}

		&_hotel-dates {
			.hotel-dates {
				&__room-info_extra-bed {
					float: left;
					padding-left: .9375rem;
					padding-right: .9375rem;
					padding-top: 7px;
					width: 75%;
				}

				&__search-date {
					margin-left: -15px;
					margin-right: -15px;
				}
			}
		}

		&_location {
			@include make-three-fourth;
			width: 70%;

			.multicity_autosuggestion {
				@include make-one-half;
				padding-left: 0px;

				.search-location_autosuggestion .itemcontainer {
					left: 0px;
					right: 0px;
				}
			}
		}

		&_location-segmentwise-cabin-class {
			width: 50%;
			padding-right: 0rem;
		}

		&_location-segmentwise-modify {
			width: 60%;
			padding-right: 0rem;
		}

		&_date {
			@include make-one-fourth;
			width: 28%;

			.search-date_select {
				width: 100%;
			}
		}

		&_date-segmentwise-cabin-class {
			padding-left: 0rem;
		}

		&_cabin-class {
			float: left;
			width: 20%;
		}

		&_flight-close {
			float: right;
			color: $brand-color-5;
			padding-top: 32px;
			cursor: pointer;
		}

		&_modify {
			float: left;
			width: 10%;
			display: block;
			padding-top: 2rem;
			font-size: $font-size-h4;

			button {
				font-weight: 600;
				background: transparent;
				color: $brand-color-1;
				padding: 0;
			}

			button[disabled] {
				cursor: not-allowed;
				opacity: 0.65;
			}
		}

	}

	&__search-traveller {
		@include make-row;
		@include clearfix;

		&_adult,
		&_child,
		&_infant,
		&_class {
			@include make-one-fourth;

			input {
				text-align: center;
			}
		}

		&_round-trip-class {
			width: 50%;
			display: table;

			.round-trip-class {
				&__cabin-class {
					float: left;
					padding-right: 0.9375rem;
					padding-left: 0.9375rem;
					margin-bottom: 1rem;
					width: 100%;
				}
			}
		}

		&_luggage {
			padding-right: 0.9375rem;
			padding-left: 0.9375rem;
			margin-bottom: 1rem;
		}
	}

	&__advance-search {
		&_title {
			background-color: lighten($brand-color-3, 10);
			padding: 0.5rem 0.5rem 0rem;
			cursor: pointer;
		}
	}

	&__advance-search-params {
		@include make-row;

		&_stops,
		&_refundable {
			@include make-one-half;
		}
	}

	&__search-supplier {
		@include make-row;
		padding-left: 1rem;
	}

	&__supplier-selection {
		label {
			padding-right: 2rem;
		}
	}

	&__additioal-option {
		@include make-row;

		&_content {
			color: #087dc2;
			background-color: #f3f9fd;
			font-size: .875rem;
			margin-left: 1px;
			margin-right: 1px;
			margin-bottom: 10px;
			padding: 5px;
		}
	}

	&__search-submit {
		@include make-row;

		&_button {
			@include make-one-half;
			float: right;

			.submit_button {
				@include button-size($base-padding*0.2, $base-padding*0.5, 1rem, $line-height, 0);
				@include make-button($light, $sf-submit-bg-color, $light, darken($sf-submit-bg-color, 20));
				text-transform: uppercase;
				width: 100%;
			}
		}
	}

	&__search-form_container {

		input[type="text"],
		input[type="number"],
		select {
			width: 100%;
			padding: 0.5rem 1rem;
			background: none;
			font-size: 0.875rem;
			vertical-align: middle;
			line-height: normal;
			border: 1px solid;
			border-color: $input-border-color;
			max-height: 34px;
		}

		input[type="text"],
		input[type="number"] {
			background-color: $input-bgcolor;
			border: 1px solid;
			border-color: $input-border-color;
			max-height: 34px;
			position: relative;
		}

		.number-stepper {
			max-width: 250px;

			input[type="text"] {
				text-align: center;
			}

			button[disabled] {
				cursor: not-allowed;
				color: $gray-light;

				&:hover {
					background-color: $gray-light;
					color: $light;
				}
			}
		}

		label {
			margin-bottom: 0.25rem;
			color: $sf-label-color;
			font-size: 0.875rem;
		}
	}

	&__advertise {
		padding: $base-padding;
		background-color: $advertise-bgcolor;
		margin-top: 2.375rem;
		min-height: 300px;

		p {
			margin-bottom: 0;
		}


	}

	&__search-time,
	&__search-date,
	&__input-field {
		@include make-row;
		margin-bottom: 1rem;

		&_select {
			@include make-one-fourth;
		}

		&_half {
			@include make-one-half;
		}
	}
}

@mixin create-search-form {
	@include create-search-form_private;
}

.search-location-checkbox {
	padding-top: 0.2375rem;
	padding-left: 0.9375rem;
	padding-right: 0.9375rem;
}

.multi-select-dropdown {
	float: left;
	padding-left: 0.9375rem;
	padding-right: 0.9375rem;
	min-height: 1px;
	width: 50%;
	position: relative;

	.glyphicon-ok {
		width: 8%;
		float: right;
		font-size: 1rem;
	}
}