.air-pax-type-fare-details {
	&__pax-detail-overview {
		@include clearfix;
		
		&_pax-type-container {
			@include clearfix;
			float: left;
			width: 30%;
			font-size: 1.25rem;
	    	
	    	.pax-type-container {
	    		&__pax-type {
					float: left;
					min-width: 20%;
					margin-right: 0.3125rem;
	    		}
	    	}
		}
		
		&_total-fare {
			float: left;
			width: 25%;
			text-align: center;
			
			.total-fare {
				&__amount {
				    font-size: 1.25rem;
				    color: $brand-color-1;
				    font-weight: 500;
				}
			}
		}
		&_multiply {
			float: left;
			width: 5%;
		    font-size: 1.25rem;
			text-align: center;
		}
		&_no-of-pax {
			float: left;
			width: 15%;
		    font-size: 1.25rem;
			text-align: center;
		}
		
		&_pax-type-expanded {
			color: $gray;
		}
		
		&_total {
			@include clearfix;
			float: left;
			width: 25%;
			
			.total-price-container {
				float: right;
				width: auto;
				&__price {
					@include clearfix;
					float: left;
    				
					&_currency {
					    margin-right: 5px;
					}
					&_amount {
					    font-size: 1.25rem;
					    color: $brand-color-1;
					    font-weight: 500;
					}
					
				}
				
				&__expand-collapse-icon {
					float: left;
					cursor: pointer;
					font-size: 1.5rem;
					margin-top: -3px;
					margin-left: 0.5rem;
					min-width: 25px;
	    		}
	    		&__expand-collapse-icon-expanded {
	    			color: $gray;
	    		} 
			}
			
		}
	}
	
	
	&__details-container {
		border: 2px solid $brand-color-3;
		
		&_header-row, &_value-row, &_header-row-with-penalty, &_value-row-with-penalty {
			@include clearfix;
			
			.header-row {
				&__label {
					float: left;
					width: 25%;
					text-transform: uppercase;
    				color: $brand-color-2;
    				padding: 0.25rem;
    				text-align: center;
				}
			}
			
			.value-row {
				&__label {
					float: left;
					width: 25%;
					text-transform: uppercase;
    				padding: 0.25rem;
    				text-align: center;
    				
    				&_expander {
    					cursor: pointer;
    				}
    				&_expanded {
   						color: $gray;
    				}
				}
			}
		}
		
		&_header-row-with-penalty, &_value-row-with-penalty {
			.header-row {
				&__label {
					width: 20%;
				}
			}
			.value-row {
				&__label {
					width: 20%;
				}
			}
		}
	}
	
}