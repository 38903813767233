.remarks-display {
	padding: 0.725rem;
	border: 10px solid $brand-color-3;
	margin-bottom: 0.725rem;
	font-family: $font-family-number;
	
	&__container {
		&_footer-btn {
			.save-btn, .close-btn {
			  display: table;
			  content: '';
			  padding: 0.625rem 8rem 0.625rem 8rem;
			  background: $brand-color-1;
			  color: $light;
			  margin-right: 0.635rem;
			  cursor: pointer;
			  
			  &:hover {
					color: $light;
				    text-decoration: none;
				    background-color: #9b0d11;
			  }
			}
			.save-btn {
				float: left;
			}
			.save-btn-disabled {
				cursor: not-allowed;
			    opacity: 0.65;
			    box-shadow: none;
			}
		}
		
		&_content {
			padding-bottom: 1rem;
		} 
		
		&_header {
			.remarks-header {
		    	font-size: 1.25rem;
		   		border-bottom: 1px solid $brand-color-3;
		    	padding-bottom: 0.5rem;
		    	margin-bottom: 0.5rem;
		    	color: $brand-color-2;
				width: 100%;
				float: left;
				text-align: center;
			}
		}
	}
}