.multi-credit-product-detail {
    &__title{
   		background-color: $brand-color-3;
    	color: $brand-color-2;
    	font-size: 1.25rem;
   		padding-left:.5rem;
    	width: 100%;
    	font-weight: 500;
    }
	&__content {
		width: 100%;
		display: table;
		content:'';
	    border: 2px solid $brand-color-3;
        padding: 0.5rem;
		&_section {	
			float: left;
   			padding-left: .9375rem;
    		padding-right: .9375rem;
    		min-height: 1px;
    		width: 50%;	
		}
	}
}