.insurance-pax-details {
	@include clearfix;
	&__title {
		font-size: 1.15rem;
    	color: $brand-color-2;	
	}
	&__content {
		@include clearfix;
		padding-top: 0.3125rem;
		padding-bottom: 0.3125rem;
		margin: 0.5rem;
		width: 100%;
		&_pax-basic-details {
			float: left;
			width: 40%;
			.pax-basic-details {
				&__pax-name {
					font-size: 0.9375rem;
					color: $brand-color-2;
				}
				&__email,&__phone{
					font-size: 0.6375rem;
					color: $brand-color-2;
				}
				&__policy-number {
					font-size: 0.75rem;
				}
				&__pax-details-container {
					float: left;
					&_details {
						@include clearfix;
						font-size: 0.875rem;
					}
				}
			}
		}
		&_pax-policy-details {
			float: left;
			width: 40%;
			.pax-policy-details {
				&__insurance {
					@include clearfix;
					font-size: 0.875rem;
					margin-top: 0.25rem;
					
					&_info, &_policy-number-container {
						float: left;
						word-wrap: break-word;
					}
					
					&_policy-number-container {
						.policy-number-container {
							cursor: pointer;
							&__policy-number {
								font-size: 1rem;
								font-family: $font-family-number;
								text-decoration: underline;
							} 
							&__download-icon {
								color: $green;
								padding-left: 0.325rem;
							}
						}
					}
					&_policy-number {
						width: 60%;
					}
				}
				&__insurance-headings {
					@include clearfix;
					font-size: 0.875rem;
					color: $brand-color-2;
    				
					&_policy-number {
						float: left;
					}
				}
			}
		}
		.total-price-container {
				float: right;
				width: 20%;
				&__price {
					@include clearfix;
					float: left;
    				
					&_currency {
					    margin-right: 0.3125rem;
					}
					&_amount {
						font-family: $font-family-number;
					    font-size: 1.25rem;
					    color: $brand-color-1;
					    font-weight: 200;
					}
				}
				&__expand-icon {
					float: left;
					cursor: pointer;
					font-size: 1.5rem;
					margin-top: -0.1875rem;
					margin-left: 0.5rem;
					min-width: 1.5625rem;
	    		}
	    		&__expanded-icon {
	    			color: $gray;
	    		} 
			}
	  }
}