.sightseeing-details__container {
	@include clearfix;
	
	&_sightseeing-basic-info {
		overflow: hidden;
		
		.sightseeing-basic-info {
			float: left;
		
			&__sightseeing-name {
				font-size: 2rem;
				color: $brand-color-2;
			}
			
			&__location {
				@include clearfix;
				font-size: 1.15rem;
				
				&_city {
					float: left;
				}
				&_seperator {
					float: left;
				}
				&_country {
					float: left;
					margin-left: 0.5rem;
				}
			}
			
			&__date {
				font-size: 1rem;
			}
		}
		
	}
	
}