.insurance-details-container {
	@include clearfix;
	
	&_insurance-basic-info {
		margin-bottom: 10px;
		.insurance-basic-info {
			&__plan-name {
				font-size: 1.5rem;
				color: $brand-color-2;	
			}
		}
	}
	
	&__booking-detail {
		&_destination , &_source, &_insured-members, &_pnr-number {
			font-size: 1rem; 
			width: 50%;
    		float: left;
			.destination, .source , .insured-members, .pnr-number{
				&__title{
					color: $brand-color-2;	
				}
			}
		}
		
	}
}