.common-total-price-details {
	border: 2px solid $gray-lighter;
	&__price-row {
		font-size: .875rem;
		padding: 0.5rem 1rem;
    	@include clearfix;
    	
    	&_label, &_price-container {
    		float: left;
    		width: 50%;
    	}
		
		&_label {
			font-size: 1.25rem;
		}
		
		&_label-expanded {
			color: $gray;
		}
		
		&_price-container {
    		float: left;
    		width: 50%;
    		
	    	.price-container {
		    	@include clearfix;
	   			float: right;
	   			
	   			&__currency, &__amount, &__expander {
	   				float: left;
	   			}
	   			
	   			&__currency {
	   				margin-right: 5px;
	   				margin-top: 6px;
	   			}
	   			
	   			&__amount {
	   				font-size: 1.25rem;
	   				color: $brand-color-1;
	   				font-weight: 500;
	   				font-family: $font-family-number;
	   			}
	   			
	   			&__expander {
   					font-size: 1.5rem;
					margin-top: -3px;
					cursor: pointer;
					margin-left: 0.5rem;
					min-width: 25px;
	   			}
	   			
	   			&__expanded {
   					color: $gray;
	   			}
	   		}
    	}
    	    	
    }
    &__price-content{
    	padding: 0 0.9rem 0.5rem 0.9rem;
    }
    &__extra-details {
		@include create-fare-details-extra-content(60%, 2%);
    }

}
.common-total-price-breakup{
	&__details-container {
		border: 2px solid $gray-lighter;
	
		&_header-row, &_value-row {
			@include clearfix;
			
			.header-row {
				&__label {
					float: left;
					width: 33.33%;
					text-transform: uppercase;
    				color: $brand-color-2;
    				padding: 0.25rem;
    				text-align: center;
				}
			}
			
			.value-row {
				&__label {
					float: left;
					width: 33.33%;
					text-transform: uppercase;
    				padding: 0.25rem;
    				text-align: center;
    				font-family: $font-family-number;
    				&_expander {
    					cursor: pointer;
    				}
    				&_expanded {
   						color: $gray;
    				}
				}
			}
		}
	}
}

.common-other-charges-details {
	@include create-fare-details-extra-content(60%, 5%);
}