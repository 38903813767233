.emd-details{
	padding: 0.725rem;
	border: 10px solid $brand-color-3;
	margin-bottom: 0.725rem;
	
	&__heading {
	    color: $brand-color-2;
	    font-size: 1.25rem;
	    text-align: center;
	    border-bottom: 1px solid $brand-color-3;
	    padding-bottom: .5rem;
	    margin-bottom: .5rem;
	}
	&__list {
		&_header {
			@include clearfix;
			background-color:  $brand-color-2;
			.emd-list-header {
				color:  $brand-color-3;
				text-align: left;
				font-weight: 700;
			}
		}
		&_content {
			box-shadow: $brand-color-22 2.4px 2.4px 3.2px;
			display: table;
			width: 100%;
		}
		&_header > .emd-list-header,
		&_content > .emd-list-content {
			@include clearfix;
			float: left;
			font-size: 0.875rem;
			padding: 0.5rem;
			width: 33%;
			word-break: break-word;
			text-align: left;
		}
	}
	&__control-buttons {
		text-align: center;
		&_close {
			@include make-button($light, $row-book-button-bgcolor, $light, darken($row-book-button-state,20));
			float : none;
			margin-left: 0.5rem;
			margin-right: 0.125rem;
			margin-top: 0.525rem;
			padding: 0.525rem;
			width: 30%;
		}
	}
}