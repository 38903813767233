.fare-rule-container{
	.nav-tabs {
	    border-bottom: 0;
		li > a {
    		color: $brand-color-2;
    		background-color: $brand-color-3;
			@include no-border;
			font-size: $font-size-h5;
			font-family: $font-family-base;
		}
		
		li.active > a {
			color: $light;
		    background-color: $brand-color-2;
			@include no-border;
			font-size: $font-size-h5;
			font-family: $font-family-base;
		}
	}
	
	.link{
		color: $brand-color-1;
    	text-decoration: none;
	}
}