 @mixin create-review-nav_private{
 
 .nav {
      margin-left: -$base-margin*0.5;
      margin-right: -$base-margin*0.5;
      background-color: $brand-color-3;
    }
    .nav-tabs {
      border-bottom: none;
    }
    .nav-tabs > li > a {
      margin-right: 0;
      border: none;
      border-radius: 0;
      color: $brand-color-2;
      font-size: $font-size-h4;
    }
    .nav > li > a:hover,
    .nav > li > a:focus {
      background-color: $light;
    }
    .nav-tabs > li.active > a,
    .nav-tabs > li.active > a:hover,
    .nav-tabs > li.active > a:focus {
      color: $brand-color-2;
      background-color: $light;
      border: none;
      cursor: default;
    }
  }

@mixin  create-review-nav{ 
	@include create-review-nav_private; 
}