.air-transaction-history {
	padding: 0.725rem;
	border: 10px solid $brand-color-3;
	margin-bottom: 0.725rem;
	font-family: $font-family-number;
	
	&__heading-container {
		@include clearfix;
		
		&_heading, &_close-button {
			float: left;
	   		font-size: 1.25rem;
		    border-bottom: 1px solid $brand-color-3;
		    padding-bottom: 0.5rem;
		    margin-bottom: 0.5rem;
		}
		
		&_heading {
		    width: 90%;
		    color: $brand-color-2;
		    text-align: center;
		}
		
		&_close-button {
			width: 10%;
			text-align: right;
			cursor: pointer;
		}
	}
	
	&__details {
		&_info-container {
			@include clearfix;
			padding: 0.125rem;
			
			.info-container {
				@include clearfix;
				margin-bottom: 0.25rem;
			
				&__activity-container {
					@include clearfix;
					width: 100%;
					font-size: 1rem;
					color: $gray;
					
					&_activity-by-container {
						@include clearfix;
						float: left;
						width: 100%;
						
						.activity-by-container {
							&__updated-by-label, &__updated-by-value, &__updated-by-separator, &__activity-date-value, &__updated-on-label, &__updated-on-value {
								float: left;
								margin-right: 0.25rem;
							}
							
							&__updated-by-label, &__updated-on-label, &__updated-on-separator, &__updated-by-separator {
								color: $dark-gray;
							}
							
							&__updated-by-value, &__activity-date-value, &__updated-on-value {
								font-weight: bold;
							}
						}
					}
					
					&_activity-date {
						@include clearfix;
						float: left;
						width: 50%;
						
						.activity-date-container {
							&__label, &__separator,k &__value {
								float: left;
							}
							&__separator {
								margin-left: 0.25rem;
							    margin-right: 0.25rem;
							}
						}
					}
					
					&_booking-status-container {
						float: left;
						width: 100%;
						
						.booking-status-container {
							&__label, &__separator, &__value {
								float: left;
							    margin-right: 0.25rem;
							}
							&__separator {
								margin-left: 0.25rem;
							}
							&__booking-status {
								&_changed-from, &_value, &_to {
									float: left;
								    margin-right: 0.25rem;
								}
								&_changed-from, &_to {
									color: $dark-gray;
								}
								&_value {
									font-weight: bold;
								}
								&_to {
									text-transform: lowercase;
								}
							}
							&__label {
								color: $dark-gray;
							}
							&__value {
								font-weight: bold;
								&_ttl{
									color:$brand-color-1;
								}
							}
						}
					}
					
					&_remarks {
						float: left;
						width: 50%;
					    font-size: 1rem;
						@include clearfix;
												    
						.remarks {
							&__remarks-wrapper, &__non-api-remarks-wrapper, &__supplier-error-wrapper {
								float: left;
    							width: 100%;
    							
    							&_container {
    								.remarks-container, .non-api-remarks-container, .supplier-error-container {
										&__label, &__separator, &__value {
											float: left;
											word-break: break-all;
										}
										&__label, &__separator {
											color: $dark-gray;
										}
										&__separator {
											margin-left: 0.25rem;
										    margin-right: 0.25rem;
										}
    								}
    							}
    							
							}
						}
					}
				}
				
				&__pax-details {
					float: left;
					width: 50%;
					
					&_pax-name-container {
						font-size: 0.9375rem;
						color: $brand-color-2;
						
						.pax-name-container {
							&__label, &__separator, &__value {
								float: left;
							}
							&__separator {
								margin-left: 0.25rem;
							    margin-right: 0.25rem;
							}
						}
					}
				}
				
				&__sector-details {
					float: left;
					width: 50%;
					
					&_heading-container {
						font-size: 0.9375rem;
						color: $brand-color-2;
						
						.heading-container {
							&__sectors {
								width: 25%;
								float: left;
							}
							&__tckt-num {
								width: 50%;
								float: left;
							}
							&__status {
								width: 25%;
								float: left;
							}
						}
					}
					&_value-container {
						.value-container {
							&__sectors {
								width: 25%;
								float: left;
							}
							&__tckt-num {
								width: 50%;
								float: left;
							}
							&__status {
								width: 25%;
								float: left;
								word-wrap: break-word;
							}
						}
					}
				}
				
/* 				&__heading-container { */
/* 					@include clearfix; */
/* 					width: 100%; */
/* 					background-color: $brand-color-3; */
					
/* 					&_title { */
/* 						float: left; */
/* 						width: 20%; */
/* 						padding: 0.125rem; */
/* 					} */
/* 				} */
				
/* 				&__value-container { */
/* 					@include clearfix; */
/* 					width: 100%; */
					
/* 					&_value { */
/* 						float: left; */
/* 						width: 20%; */
/* 						padding: 0.125rem; */
/* 						min-height: 1px; */
/* 						word-wrap: break-word; */
/* 					} */
/* 				} */
				
				&__separator {
					height: 3px;
				    border-bottom: 1px solid lightgrey;
				    margin: auto;
				    margin-top: 1rem;
				    margin-bottom: 1rem;
				    width: 100%;
				}
			}
		}
		&_loader{
			text-align:center;
		}
	}
}
.remarks{
	&__view-remarks-wrapper {
		&_container {
			.remarks-container {
				&__label-view-remarks{
					 &_toggle-indicator, &_label-content {
						   color: $brand-color-2;
						   cursor: pointer;
					 }
					 &__label-content {
				    	   width: 100%;
					 } 
				     &_toggle-indicator {
				     	   font-size: 0.90rem;
					 }
				 }
			 }
		}
	}
}