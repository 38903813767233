@mixin draw-triangle_private($triangle, $size,$color) {
  height: 0;
  width: 0;
  border-color:white;
  // border-style: solid;
  border: $size solid transparent;
  @if $triangle=='top' {
    border-bottom: $size solid $color;
  }
  @else if $triangle=='bottom' {
    border-top: $size solid $color;
  }
  @else if $triangle=='left' {
    border-right: $size solid $color;
  }
  @else if $triangle=='right' {
    border-left: $size solid $color;
  }
}


@mixin  draw-triangle($triangle, $size,$color) { 
	@include draw-triangle_private($triangle, $size,$color); 
}

.modal__header {
  color: $brand-color-2;
  font-size: $font-size-h4;
  padding-bottom : 10px;
  &_heading {
    padding-left: 0.125rem;
    float:left
  }
  &_close{
    float:right;
    cursor:pointer;
  }
  &_info {
    //color:$brand-color-2;
    margin-top: 7px;
    font-size: $font-size-h3;
    background-color: lighten($brand-color-3,8);
    height: 35px;
    padding: 5px 5px 5px 7px;
    padding-left: 0.125rem;
  }
}
.heading__main{
  @include clearfix;
}
.header__info_part {
  color: $brand-color-2;
  float: left;
}
.header__info_part2 {
  color: $brand-color-2;
  float: right;
  font-size: 0.875rem;
  margin: 2px;
}

.header__info_subpart1{
	padding-right: 10px;
}
.header__info_subpart2{
	padding: 3px 20px 3px 20px;
    background-color: white;
}

.header__info_part1 {
  color: lighten($brand-color-1,20);
  //  font-family-number:$font-family-number;
  margin-left: 5px;
  font-family: $font-family-number;
}
.modal {
   &__content {
  overflow-y:auto;
  margin-bottom: 30px;
  padding: 0;
  max-height: $modal-md-content-size;
  margin: 1.25rem 0;
  	&_fare-rule-loader{
  	@include make-loader;
  	}
 }
 
   &__fare-rule-remark{
	color : $brand-color-2;   
   } 
}

.modal-content{
  padding: $base-padding*0.5;
  @include clearfix();
}
.panel-group{
  padding-right:0px;
}
.panel {
  margin-bottom:6px;

}
.panel__heading {
  @include clearfix;
  color: $dark;
  background-color: lighten($brand-color-3,8);
  font-size: $font-size-h5;
  //font-family: $font-family-number;
  font-weight-base: $font-weight-base;
  padding: 5px 5px 5px 7px;
  position: relative;
  cursor: pointer;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.25);


  .triangle__container {
    border-radius: 100%;
    width: 18px;
    height: 18px;
    background-color: black;
    position: relative;
    float: right;
    //overflow:auto;
  }
  .triangle {
    @include draw-triangle(bottom, 5px,$light);
    position: absolute;
    top: 7px;
    left: 4px;
    // right:0;
  }

  &.collapsed {
    color: $brand-color-5;

    .triangle {
      @include draw-triangle(right, 5px,$light);
      top: 4px;
      left: 7px;
    }
    .triangle__container{
      background-color: lighten($brand-color-5,10);
    }
  }
  &_highlight {
    background-color: $sky-blue;
    .triangle__container{
      	background-color: $dark-blue;
    }
    &.collapsed {
    	.triangle__container{
      		background-color: $dark-blue;
    	}
    }
  }
}
.panel__body {
  padding: 7px 0 3px 7px;
  border-left: 1px solid $body-bg;
  border-right: 1px solid $body-bg;
  border-bottom: 1px solid $body-bg;
}
.panel__heading_info {
  float: left;
  color:$gray-dark;
}
