.transfer-transaction-details {
	&__content {
		font-size: 1rem;
		&_title {
			color: $brand-color-2;
		}
	}
	
	&__erp-detail{
	  font-size: 1.2rem;
      color: $brand-color-2;
      padding-left: 0.5rem;
      padding-top: 0.2rem;
	}
}