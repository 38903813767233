.dp-booking-summary {
	&__invoice-link {
		color: $brand-color-1;
		&:hover{
			color: darken($brand-color-1, 20);
		}
	}
	&__package-info {
		@include clearfix;
	    width: 100%;	
		padding: 0.3125rem;
		
		&_package-basic-info {
			@include clearfix;
	    	float: left;
		    width: 50%;
	    	
			.package-basic-info {
				&__package-name {
				    font-size: 2rem;
				    color: $brand-color-2;
				}
				&__package-star-rating {
				    font-size: 1rem;
					color: $star-rating;
				}
				
				&__package-details {
					@include clearfix;
					float: left;
    				width: 100%;
					
					&_package-duration {
						float: right;
				   		padding-right: 1rem;
				   		padding-top: 0.25rem;
				   		font-size: 1rem;
				   		color: $brand-color-2;				
					}
					&_package-type {
						float: right;
					    font-size: 1rem;
					    background-color: $brand-color-3;
					    color: $brand-color-2;
					    padding: .3125rem .9375rem;
					}
				}
				
				&__pricing-info {
					@include clearfix;
					float: right;
					
					&_currency {
						float: left;
					    color: $brand-color-2;
				        padding-top: 0.75rem;
	    				padding-right: 0.3125rem;
					}
					&_amount {
						float: left;
					    color: $brand-color-1;
					    font-size: 1.25rem;
					}
				}
			}
		}
		
		&_package-extra-info {
			float: left;
			width: 100%;
			padding-top: 0.625rem;
			
			.package-extra-info {
				&__title {
					color: $brand-color-2;
    				font-size: .9375rem;
				}
				&__value {
					
				}
			}
		}
		
	}
}

.booking-product__products-header {
	font-size: 1.5rem;
    padding-top: 0.625rem;	
}