.ttl-update-form {
	@include create-transition-form;
	@include make-input;
    @include hide-number-spinner;

	&__transition-form_form {
		float: left;
	}
	
	&__control-buttons {
		float: left;
		margin-top: 1rem;	
	}
		 
	&__field {
		width: 25%;
		min-height: 4.75rem;
	}	
	
.input-group-btn {
  .btn {
    padding: .475rem 1rem;
    background: none;
    border-radius: 0;
    border: 1px solid lighten($brand-color-5, 30);
    color: $brand-color-2;
    line-height: $line-height-base;
    max-height: 2.125rem;
    &:hover {
      color: $light;
      background-color: $brand-color-2;
    }
  }
}

.glyphicon, button {
    display: inline-block;
    font-weight: 400;
}
	
}