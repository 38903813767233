.common-rfq-transaction-history {
	padding: 0.725rem;
	border: 10px solid $brand-color-3;
	margin-bottom: 0.725rem;
	font-family: $font-family-number;
	
	&__heading-container {
		@include clearfix;
		
		&_heading, &_close-button {
			float: left;
	   		font-size: 1.25rem;
		    border-bottom: 1px solid $brand-color-3;
		    padding-bottom: 0.5rem;
		    margin-bottom: 0.5rem;
		}
		
		&_heading {
		    width: 90%;
		    color: $water-blue;
		    text-align: center;
		}
		
		&_close-button {
			width: 10%;
			text-align: right;
			cursor: pointer;
		}
	}
	
	&__details {
		&_info-container {
			@include clearfix;
			padding: 0.125rem;
			
			.info-container {
				@include clearfix;
				margin-bottom: 0.25rem;
			
				&__activity-container {
					@include clearfix;
					width: 100%;
					font-size: 1rem;
					color: $gray;
					
					&_activity-by-container {
						@include clearfix;
						float: left;
						width: 60%;
						
						.activity-by-container {
							&__updated-by-label, &__updated-by-value, &__updated-by-separator, &__activity-date-value, &__updated-on-label, &__updated-on-value {
								float: left;
								margin-right: 0.25rem;
							}
							
							&__updated-by-label, &__updated-on-label, &__updated-on-separator, &__updated-by-separator {
								color: $dark-gray;
							}
							
							&__updated-by-value, &__activity-date-value, &__updated-on-value {
								font-weight: bold;
							}
						}
					}
					&_booking-status-container {
						float: left;
						width: 40%;
						
						.booking-status-container {
							&__label, &__separator, &__value {
								float: left;
							    margin-right: 0.25rem;
							}
							&__separator {
								margin-left: 0.25rem;
							}
							&__booking-status {
								&_changed-from, &_value, &_to {
									float: left;
								    margin-right: 0.25rem;
								}
								&_changed-from, &_to {
									color: $dark-gray;	
								}
								&_value {
									font-weight: bold;
								}
								&_to {
									text-transform: lowercase;
								}
							}
							&__label {
								color: $dark-gray;
							}
							&__value {
								font-weight: bold;
							}
						}
					}
				}				
				&__separator {
					height: 3px;
				    border-bottom: 1px solid lightgrey;
				    margin: auto;
				    margin-top: 1rem;
				    margin-bottom: 1rem;
				    width: 100%;
				}
			}
		}
		&_loader{
			text-align:center;
		}
	}
}