.common-payment-details {
	&__title {
		color: $brand-color-2;
		font-size: 1.25rem;
	}
	&__content {
		&_container {
			@include clearfix;
			border: 2px solid $gray-lighter;
			width: 100%;
		}
	}
	&__extra-content {
		&_container {
			@include clearfix;
			border: 2px solid $gray-lighter;
			width: 100%;
			padding: 0.5rem;
		}
	}
	.extra-content{
		&__title {
			width: 30%;
    		float: left;
    		color: $brand-color-2;
			font-size: 1rem;
		}
		&__value {
			width: 70%;
    		float: left;
    		font-size: 0.9rem;
    		overflow-wrap: break-word;
		}
	}
	.payment-details {
		&__header {
			@include clearfix;
			border-bottom: 1px solid $gray-lighter;  
			&_payment-mode,
			&_payment-amount {
				float:left;
				padding: 0.5rem 1.25rem;
				width: 50%;
			}
			&_payment-mode {
				font-size: 1.25rem;
			}
			&_payment-amount{
				float: left;
			}
		}
		&__content {
			font-size: 0.875rem;
			&_common-details{
				padding: 0.5rem 1.25rem;
			}
			&_card-details.panel {
				margin-bottom: 0;
				border-bottom: 2px solid $gray-lighter;
				&:last-child {
					border-bottom: 0;
				}
			}
			
		}
	}
	.accordion-expanded {
		font-weight: 700;
	}
	.payment {
		float: right;
		@include clearfix;
		&__currency,
		&__value, 
		&__toggle-icon {
			float: left;
		}
		&__currency {
			margin: 0.375rem 0.375rem;
		}
		&__value {
			color: $brand-color-1;
			font-family: $font-family-number;
			font-size: 1.25rem;
		}
		&__toggle-icon {
			font-size: 1.5rem;
		    margin-left: 0.5rem;
		}
	}
	.common-details {
		&__lpo-number,
		&__reference-no,
		&__remarks,
		&__bank-name,
		&__bank-receipt-no,
		&__card-type,
		&__ccr-key,
		&__approval-code,
		&__cpg-void-msg {
			&_label {
				float: left;
				font-weight: 700;
				text-transform: uppercase;
				width: 30%;
			}
			&_details-separator {
				margin-right: 1rem;
			}
		}
	}
	.card-details {
		&__header{
			@include clearfix;
			background-color: darken($light, 3%);
			padding: 0.5rem 1.25rem;
			width:100%;
			&_card-no,
			&_amount {
				font-family: $font-family-number;
				float:left;
				width:50%;
			}
			&_amount {
				float: right;
				text-align: right;
			}
		}
		&__content {
			font-size: 0.875rem;
			padding: 0.5rem 1.25rem;
		}
	}
	.card-holder,
	.pos-id,
	.batch-no,
	.approval-code {
		&__label {
			float: left;
			font-weight: 700;
			text-transform: uppercase;
			width: 20%;
		}
		&__details-separator {
			margin-right: 1rem;
		}
	}
	.card-no {
		&__logo,
		&__prefix,
		&__value {
			float: left;
		}
		&__prefix,
		&__value { 
			font-weight: 600;
			font-size: 1rem;
			margin-left: 0.375rem;
			margin-top: 0.1875rem;
		}
		&__logo{
			font-size: 1.25rem;
		}
	}
	.card-amount {
		float: right;
		&__currency,
		&__value,
		&__toggle-icon {
			float: left;
		}
		&__currency {
			margin: 0.375rem 0.375rem;
		}
		&__value {
			font-size: 1.25rem;
			color: $brand-color-1;
		}
		&__toggle-icon {
			font-size: 1.5rem;
			margin-left: 0.5rem;
		}
	}
}