// Button sizes
@mixin button-size_private($padding-vertical, $padding-horizontal, $font-size, $line-height, $border-radius) {
  padding: $padding-vertical $padding-horizontal;
  font-size: $font-size;
  line-height: $line-height;
  border-radius: $border-radius;
}

@mixin button-size($padding-vertical, $padding-horizontal, $font-size, $line-height, $border-radius){
  @include button-size_private($padding-vertical, $padding-horizontal, $font-size, $line-height, $border-radius);
}

@mixin make-button_private($color, $bg-color, $hover-color, $hover-bgcolor) {
  color: $color;
  background-color: $bg-color;
  &,
  &:active,
  &.active {
    &:focus,
    &.focus {
      @include tab-focus;
    }
  }

  &:hover,
  &:focus,
  &.focus {
    color: $hover-color;
    text-decoration: none;
    background-color: $hover-bgcolor;
  }

  &:active,
  &.active {
    outline: 0;
    background-image: none;
    @include box-shadow(inset 0 3px 5px rgba(0,0,0,.125));
  }

  &.disabled,
  &[disabled],
  fieldset[disabled] & {
    cursor: $cursor-disabled;
    @include opacity(.65);
    @include box-shadow(none);
  }

  // [converter] extracted a& to a.btn
}

@mixin make-button($color, $bg-color, $hover-color, $hover-bgcolor){
  @include make-button_private($color, $bg-color, $hover-color, $hover-bgcolor);
}

@mixin make-infobutton_private($bg-color, $width){
	padding: .3125rem 0;
	font-size: .625rem;
	line-height: inherit;
	-webkit-border-radius: 0;
	border-radius: 0;
	color: #fff;
	background-color: $bg-color;
	width: $width;
	font-weight: 700;
	text-transform: uppercase;
	padding-right: 1.8rem;
}

@mixin make-infobutton($bg-color, $width){
  @include make-infobutton_private($bg-color, $width);
}

@mixin social-site-button($bg-color) {
    position: relative;
    padding-left: 44px;
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color:$light;
    background-color: $bg-color;
    display: block;
    width: 100%;
    padding: 6px 12px;
    margin-bottom: 0;
    margin-top: 5px;
    font-size: 14px;
    font-weight: normal;
    line-height: 1.42857143;
    touch-action: manipulation;
    cursor: pointer;
    background-image: none;
    border: 1px solid transparent;
    border-radius: 0px;
    content: "\f09a";
}

@mixin social-site-button_private($bg-color){
  @include social-site-button($bg-color);
}

@mixin make-round-button_private($color, $button-size) {

	>button {
   		background-color: $light;
		color: $color;
		height: $button-size;
		width: $button-size;
	}
	
	>button:focus, :hover {
		background-color: $color;
		border-radius: 100%;
		color: $light;
		font-size: .75rem;
	}
}

@mixin make-round-button($color, $button-size) {
  @include make-round-button_private($color, $button-size);
}

@mixin delete-icon_private() {

	>button {
   		background-color: $light;
	}
	>button:focus, :hover {
   		>span {
   			color: $brand-color-1;
		}
	}
	
	.remove-button {
		&__icon {
			color: $gray-light;
			font-size: 1.25rem;
		}
	}
}

@mixin delete-icon() {
  @include delete-icon_private();
}