.sightseeing-booking-duration {
	margin-top: 1.375rem;
	
	&__container {
		float: left;
		width: 40%;
		font-size: 0.875rem;
		text-align: center;
		
		&_title {
			color: $brand-color-2;
			font-size: 1.15rem;
		}
	}
	
	&__duration-container {
		float: left;
		width: 20%;
		font-size: 0.875rem;
		text-align: center;
		
		&_title {
			color: $brand-color-2;
			font-size: 1.15rem;
		}
	}				
}