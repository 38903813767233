.air-flight-details {
	@include clearfix;
	width: 100%;
	
	&__airline-info {
		&_airline-name {
			font-size: 2rem;
			color: $brand-color-2;
		}
		
		&_airline-extra-details {
			font-size: 0.875rem;
			@include clearfix;
			width: 100%;
			
			.airline-extra-details {
				
				&__title {
					float: left;
					color: $brand-color-2;
				}
				&__separator {
					float: left;
					color: $brand-color-2;
					margin-left: .25rem;
    				margin-right: .25rem;
				}
				&__value {
					float: left;
					&_rbd-class{
						margin-left: .25rem;
					}
				}
			}
		}
		&_fare-rules{
			.fare-rules{
				&__title {
					float: left;
					color: $brand-color-2;
				}
				&__separator {
					margin-left: .25rem;
   					margin-right: .25rem;
					float: left;
				}
				&__value {
					float: left;
					cursor: pointer;
				}
			}
		}
	}
	
}