.cash-diners-details{
	@include clearfix;
	width:100%;
	padding-right:1.5rem;
	&__row{
		@include clearfix;
		.cash-diners-fieldset{
			width: 32.5%;
			@include clearfix;
			float: left;
			padding-right: 0.5rem;
			&__header{
	   				font-size: 0.875rem;	   			
	   			&_mandatory-icon{
	   				font-size: 0.5rem;
	   				float: left;
	   				color: $brand-color-1;
	   			}
	   			&_title{
					color: $sf-label-color;
					float: left;   				
	   			}
			}
			&__values{
				padding: 0.5rem 0;
				font-size: 0.875rem;
				line-height: normal;
				input[type="text"] {
			    	padding: 0.5rem 1rem;
			    	background: none;
			   	 	font-size: 0.875rem;
			    	vertical-align: middle;
			    	line-height: normal;
			    	background-color: $input-bgcolor;
			    	border: 1px solid;
			    	border-color: $input-border-color;
			   		max-height: 34px;
				}
			}
			&__error{
				@include clearfix;
				.form-field-validation-error{
					color : $brand-color-1;
	    			font-size: 0.75rem;
	    			line-height: 1rem;
	    			margin-bottom: 0;		
				}
			}
		}	
	}
}
