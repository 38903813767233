.request-revalidate {
	&__transition-form{
		&_sector-details {
			@include clearfix;
		}
		.sector-details {
			&__locator-number{
				margin-top : 1rem;
			}
			&__header-container {
				@include clearfix;
				&_title {
					background-color:$brand-color-3;
					width:25%;
					padding:0.5rem;
					float:left;
				}
			}
			&__value-container{
				.search-date_select {
					input {
						padding: 0.45rem 1rem;
						background-color: $input-bgcolor;
					    border: 1px solid;
					    border-color: $input-border-color;
					    max-height: 34px;
					    position: relative;
					}
					label{
						color: $brand-color-2;
					}
				}
				&_value{
					background-color:$light;
					width:25%;
					padding:1rem;
					float:left;
				}
			}
		}
	}
}