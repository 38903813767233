.pax-wise-adhoc-fare-edit{
	border: 0.125rem solid $brand-color-3; 
	margin-top: 1.125rem; 
	height: 5rem; 
	margin-left: 1rem; 
	margin-right: 1rem;
	
	&__header-row{
		  display: flex;
		&_label{
			flex-grow: 1;
			text-align: center;
			padding: 0.25rem;
			text-transform: uppercase;
			color: $brand-color-2;
			flex-basis: 0;
		}
		&_adhocAmount{
			flex-grow: 0.5;
			text-align: center;
			padding: 0.25rem;
			flex-basis: 0;
			padding-left: 0;
		}
		&_inpute{
			padding: .5rem;
		}
	}
}
.adhoc-edit{
	 float: right;
	 padding-right: 0.375rem;
     padding-top: 0.125rem;
     padding-bottom: 0.125rem;
     width: 13%;

   &__button{
	 
	 &_save,&_cancel{
		padding: 0.188rem;
		width: 46%;
		@include make-button($light, $brand-color-2, $light, darken($brand-color-2,20));
	 }
   }
	
}
.alertMessage{
	  padding-top: 3rem;
	  padding-left: 1rem;
      padding-right: 1rem;
}
